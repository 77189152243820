import worldwide from "../../../assets/images/worldwide.svg";
import trust from "../../../assets/images/trust.svg";
import tracking from "../../../assets/images/tracking.svg";
import blog1 from "../../../assets/images/blog1.svg";
import blog2 from "../../../assets/images/blog2.svg";
import blog3 from "../../../assets/images/blog3.svg";

export const CardDetails = [
  {
    icons: worldwide,
    title: "WORLDWIDE SERVICE",
    content:
      "Whatever the end destination for your cargo is, be sure that thanks to us it will arrive on time and even faster!",
  },
  {
    icons: trust,
    title: "TRUSTWORTHINESS",
    content:
      "Working in the industry for many years, our company trustworthiness has built us a solid reputation!",
  },
  {
    icons: tracking,
    title: "Parcel TRACKING",
    content:
      "We offer this service to track your cargo in real-time on a free basis. This is the perfect extra gift we can offer our clients!",
  },
];

export const BlogDetails = [
  {
    images: blog1,
    title: "Freight Services",
    content:
      "Efficient and reliable freight services are at the core of modern logistics, enabling the seamless transportation of goods across various distances and modes. Our freight services are tailored to meet your needs, combining advanced tracking technology with unmatched expertise for dependable and hassle-free logistics.",
    date: "",
  },
  {
    images: blog2,
    title: "Express & Courier Services",
    content:
      "Fast and dependable delivery is essential for businesses and individuals alike, which is why our express and courier services are designed to meet your time-sensitive needs. With a commitment to speed, reliability, and excellent customer service, we make sure your parcels arrive on time, every time, no matter the destination.",
    date: "",
  },
  {
    images: blog3,
    title: "Warehousing & Storage",
    content:
      "Secure and efficient storage solutions are vital for managing goods effectively. Our warehousing and storage services cater to diverse business needs, ensuring your products are stored safely and are readily accessible when needed. With state-of-the-art facilities, we provide a comprehensive storage solution for businesses of all sizes.",
    date: "",
  }
];

export const AccordionItems = [
  {
    title: "Section 1",
    content: "Content for section 1.",
  },
  {
    title: "Section 2",
    content: "Content for section 2.",
  },
  {
    title: "Section 3",
    content: "Content for section 3.",
  },
  {
    title: "Section 4",
    content: "Content for section 4.",
  },
  {
    title: "Section 5",
    content: "Content for section 5.",
  },
];
