import React from "react"
import renderProfile from "./renderProfile"
import Button from "../../components/shared-components/Button"
import useProfile from "./useProfile"

const Profile = () => {
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
    isLoading,
  } = useProfile()

  return (
    <form
      className="grid grid-cols-1 lg:grid-cols-2 gap-4 items-end w-full "
      onSubmit={handleSubmit}
    >
      {Object.entries(values).map(([field, value], i) => (
        <div key={i}>
          {renderProfile(
            field,
            value,
            handleChange,
            handleBlur,
            errors,
            touched
          )}
        </div>
      ))}

      <div>
        <Button
          type="submit"
          text={"Save changes"}
          className={"w-full text-white "}
          isSubmitting={isSubmitting || isLoading}
          disabled={isLoading}
        />
      </div>
    </form>
  )
}

export default Profile
