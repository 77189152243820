import { useNavigate } from "react-router-dom"
import { verifyPathName } from "../../../components/routes/pathName"
import { Toast } from "../../../components/toastify"
import { useFormik } from "formik"
import { signUpSchema } from "../../../common/validation/signUpValidation"
import { useDispatch, useSelector } from "react-redux"
import { signUpRequest } from "../../../Redux/action/signUpAction"

const useSignUp = () => {
  const dispatch = useDispatch()
  const { initialValues } = useSelector((state) => state.signUp)
  const navigate = useNavigate()

  const accountTypeDetails = ["Individual", "Business"]

  async function submitForm(value, setSubmitting) {
    try {
      const response = await dispatch(signUpRequest(value)).unwrap()
      sessionStorage.setItem("email", value.email_address)
      Toast("success", `${response.message}, check your email for verification`)
      navigate(verifyPathName)
    } catch (error) {
      Toast("error", error?.message)
    } finally {
      setSubmitting(false)
    }
  }

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    // isSubmitting,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: signUpSchema,
    onSubmit: async (value, { setSubmitting }) => {
      await submitForm(value, setSubmitting)
    },
  })

  return {
    accountTypeDetails,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
  }
}

export default useSignUp
