import { Navigate } from "react-router-dom"
import { loginPathName } from "../../../components/routes/pathName"
import useAuthCheck from "../authCheck"

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = useAuthCheck()

  if (!isAuthenticated) {
    return <Navigate to={loginPathName} />
  }

  return children
}

export default ProtectedRoute
