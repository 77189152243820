import { createSlice } from "@reduxjs/toolkit"
import { getShipmentRequest } from "../action/getShipmentAction"

const getShipmentSlice = createSlice({
  name: "shipment",
  initialState: {
    shipment: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getShipmentRequest.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.shipment = payload
    })
    builder.addCase(getShipmentRequest.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getShipmentRequest.rejected, (state, { payload }) => {
      state.isLoading = false
      state.error = payload.error
    })
  },
})

export default getShipmentSlice.reducer
