import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getQuoteRequest } from "../../../../Redux/action/getQuoteAction"
import { useFormik } from "formik"
import { getQuoteSchema } from "../../../../common/validation/getQuotesValidation"
import { Toast } from "../../../toastify"
import { useToggleThrough } from "../../../../Hooks/GlobalHook"
import { boolean, shipmentData } from "../../../data/optionData"

const useQuote = () => {
  const dispatch = useDispatch()
  const { quote } = useSelector((state) => state.getQuote)
  const { toggleModal, setSelected, handleToggleModal } = useToggleThrough()

  async function submit(values, setSubmitting) {
    try {
      await dispatch(getQuoteRequest(values)).unwrap()
      await handleToggleModal("getQuote")
    } catch (error) {
      Toast("error", error?.message)
    } finally {
      setSubmitting()
    }
  }

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: quote,
    validationSchema: getQuoteSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      submit(values, setSubmitting)
      resetForm()
    },
  })

  const selectOptions = {
    get_quote_type: shipmentData,
    isFragile: boolean,
    isLiquid: boolean,
  }

  return {
    toggleModal,
    setSelected,
    selectOptions,
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    isSubmitting,
  }
}

export default useQuote
