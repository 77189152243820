import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "../../common/services/httpMethod/httpMethod"
import { api } from "../../common/services/api/api"

export const getSingleShipmentRequest = createAsyncThunk(
  "get/single/shipment/request",
  async (shipmentId, { rejectWithValue }) => {
    try {
      const response = await getRequest(
        `${api.lodgeSingleShipmentGet}/${shipmentId}`
      )
      return response?.data?.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)
