import { createSlice } from "@reduxjs/toolkit"
import { getSingleShipmentRequest } from "../action/getSingleShipmentAction"

const getSingleShipmentSlice = createSlice({
  name: "getSingleShipment",
  initialState: {
    singleShipment: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getSingleShipmentRequest.fulfilled,
      (state, { payload }) => {
        state.singleShipment = payload
        state.isLoading = false
      }
    )
    builder.addCase(getSingleShipmentRequest.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getSingleShipmentRequest.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export default getSingleShipmentSlice.reducer
