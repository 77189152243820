import React, { useRef } from "react"
import Logo from "../shared-components/LogoComponent"
import { NavLink, useNavigate, Link } from "react-router-dom"
import { MdCancel } from "react-icons/md"
import { useDispatch } from "react-redux"
import { logout } from "../../Redux/slices/authSlice"
import { landingPathName } from "../routes/pathName"
import useClickOut from "../../util/useClickOut"

const SideBar = ({
  sideBarDetails,
  active,
  handleActive,
  handleToggle,
  setToggle,
}) => {
  const sideRef = useRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useClickOut(sideRef, () => setToggle(false))

  function signOut() {
    dispatch(logout())
    navigate(landingPathName)
  }

  return (
    <div
      ref={sideRef}
      className="flex flex-col w-full md:h-full drop-shadow-xl md:items-center fixed md:w-[18%] bg-white z-50"
    >
      <div className="w-full md:w-[75%] my-[3rem] px-[2rem] md:px-0">
        <div
          className="flex justify-end bg-white text-[#AFAFAF] text-[1.5rem] md:hidden cursor-pointer"
          onClick={handleToggle}
        >
          <MdCancel />
        </div>
        <div className="w-full">
          <Link to={landingPathName}>
            <Logo />
          </Link>
        </div>
        <div className="flex flex-col justify-between md:h-[65vh] w-full gap-y-[0.8rem] mt-[2.5rem]">
          <div>
            {sideBarDetails.map((data, i) => (
              <NavLink
                key={i}
                className={({ isActive }) => {
                  return `flex gap-x-[1.3rem] w-full ${
                    isActive
                      ? "text-primary-500 bg-primary-100"
                      : "text-[#777777] bg-white"
                  } rounded-[0.5rem] py-[0.8rem] px-[0.5rem] xl:w-full hover:bg-primary-100 cursor-pointer`
                }}
                onClick={() => {
                  handleActive(i)
                  setToggle(false)
                }}
                to={data.path}
              >
                <div className="md:hidden xl:block">
                  <data.icon fill={active === i ? "#0E94CD" : "#777777"} />
                </div>
                <p className="xl:text-[1rem]">{data.title}</p>
              </NavLink>
            ))}
          </div>

          <div
            onClick={signOut}
            className="bg-red-500 text-white px-4 py-2 rounded-md w-fit cursor-pointer "
          >
            Sign Out
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideBar
