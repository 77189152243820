import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { customerDataRequest } from "../../Redux/action/customerAction"
import { getShipmentRequest } from "../../Redux/action/getShipmentAction"

const useDashboard = () => {
  const dispatch = useDispatch()

  const { customer } = useSelector((state) => state.getCustomer)
  const { shipment } = useSelector((state) => state.getShipment)

  useEffect(() => {
    async function fetchProfileData() {
      try {
        await dispatch(customerDataRequest()).unwrap()
        await dispatch(getShipmentRequest()).unwrap()
      } catch (error) {}
    }

    fetchProfileData()
  }, [dispatch])

  return { customer, shipment }
}

export default useDashboard
