import { createSlice } from "@reduxjs/toolkit"
import { updateProfile } from "../action/profileAction"

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    profile: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    setInitialState: (state, { payload }) => {
      state.customer = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateProfile.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updateProfile.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.profile = payload
    })
    builder.addCase(updateProfile.rejected, (state, { payload }) => {
      state.isLoading = false
      state.error = payload.error
    })
  },
})
export const { setInitialState } = profileSlice.actions
export default profileSlice.reducer
