import React from "react"

const DataCard = ({ title, details = [] }) => {
  function status(value) {
    switch (value) {
      case "pending":
        return "text-[#FFB900]"
      case "completed":
        return "text-[#00A6ED]"
      case "failed":
        return "text-[#ed0000]"
      default:
        return "text-[#4A515C]"
    }
  }

  const gridColsClass =
    details.length >= 6
      ? "xl:grid-cols-6"
      : details.length === 5
      ? "xl:grid-cols-5"
      : "xl:grid-cols-4"

  return (
    <div className="space-y-[0.5rem] border-b-[0.1rem] ">
      <p className="text-[1rem] md:text-[1.25rem] text-[#979797] font-gothic ">
        {title} : 
      </p>
      <div className={`grid grid-cols-2  ${gridColsClass} `}>
        {details.map((data, i) => (
          <div
            className={`w-full  bg-[#EFFAFF] xl:bg-white xl:space-y-[0.5rem] ${
              i % 2 === 1 && "text-end xl:text-start"
            } `}
            key={i}
          >
            <div className="w-full text-[0.75rem] sm:text-[1rem] text-[#979797] xl:text-black sm:font-bold rounded-[0.5rem] py-[0.8rem] px-[2rem] bg-[#EFFAFF]">
              {data.label}
            </div>
            <div
              className={`w-full h-[12vh] text-[0.8rem] md:text-[1rem] xl:text-[#979797]  border-b font-light ${status(
                data.value
              )} rounded-[0.5rem py-[0.8rem] px-[2rem]  xl:bg-[#F9F9F9] `}
            >
              {data.value}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default DataCard
