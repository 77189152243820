import { createAsyncThunk } from "@reduxjs/toolkit"
import { patchRequest } from "../../common/services/httpMethod/httpMethod"
import { api } from "../../common/services/api/api"

export const validatePassword = createAsyncThunk(
  "validate/password",
  async ({ otp, value }, { rejectWithValue }) => {
    try {
      const response = await patchRequest(
        `${api.customerResetPassword}/${otp}`,
        value
      )

      return response?.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)
