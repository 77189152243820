import React, { useState } from "react";
import Logo from "../../assets/images/Logo.svg";
import hamburger from "../../assets/images/hamburger.svg";
import hamburgerClose from "../../assets/images/hamburgerClose.svg";

import Button from "../../components/shared-components/Button";
import { Link } from "react-router-dom";
 import { dashboardPathName} from "../../components/routes/pathName"

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={` ${
        isOpen ? "absolute sm:right-[5rem] sm:left-[5rem] right-0 left-0  bg-white  border-4" : "bg-transparent"
      } flex flex-col sm:flex-row gap-[3rem] justify-between p-3 `}
    >
      <div className="flex items-center justify-between w-full sm:max-w-[187px]">
        <Link to="/landing">
        <img src={Logo} alt="logo" className="text-red-300" />
        </Link>
        <button onClick={() => setIsOpen(!isOpen)} className="outline-none ">
          {isOpen ? (
            <img
              src={hamburgerClose}
              alt="hamburgerClose"
              className="block sm:hidden"
            />
          ) : (
            <img src={hamburger} alt="hamburger" className="block sm:hidden" />
          )}
        </button>
      </div>

      <div
        className={`${
          isOpen ? "block" : "hidden sm:flex "
        }  min-w-[80%] flex flex-col sm:flex-row sm:items-center  sm:justify-between space-y-[2rem]`}
      >
        
        <ul className="flex flex-col  sm:flex-row justify-between gap-[2rem] sm:items-center text-primary-300 sm:text-white text-[18px]">
          <li>
            <a hrefa href="#about" className="cursor-pointer#about">
              About
            </a>
          </li>
          <li>
            {" "}
            <a hrefa href="#services" className="cursor-pointer">
              Services
            </a>
          </li>
    
          <li>
            <a hrefa href="#contact" className="cursor-pointer">
              Contact
            </a>
          </li>
        </ul>

        <div className="flex flex-col  pt-0 mt-0  sm:flex-row gap-3">
          {
             sessionStorage.getItem("token") !== null ? (
              <Link to={dashboardPathName}>
              <Button
                text="DASHBOARD"
                className={`px-2 border border-primary-300 sm:bg-transparent w-[10rem] py-2  sm:border-white  bg-primary-300 hover:bg-white hover:text-primary-300 sm:hover:bg-white sm:hover:text-primary-300`}
              />
              </Link>
             ) : (
              <Link to="/login">
              <Button
              text="LOGIN"
              className={`px-2 border border-primary-300 sm:bg-transparent w-[10rem] py-2  sm:border-white  bg-primary-300 hover:bg-white hover:text-primary-300 sm:hover:bg-white sm:hover:text-primary-300`}
            />
          </Link>
             )
          }
         
         {
         sessionStorage.getItem("token") == null ? (
          <Link to="/sign-up">
            <Button
              text="SIGN UP"
              className={`px-2 border border-primary-300 sm:bg-transparent w-[10rem] py-2  sm:border-white bg-primary-300 hover:bg-white hover:text-primary-300`}
            />
          </Link>) : null
}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
