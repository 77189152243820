import { RxHamburgerMenu } from "react-icons/rx"
import useDashboard from "../../pages/Dashboard/useDashboard"

const Header = ({ handleToggle, title }) => {
  const { customer } = useDashboard()
  const firstName = customer?.firstname?.charAt(0)?.toUpperCase()
  const lastName = customer?.lastname?.charAt(0)?.toUpperCase()
  const userName = `${firstName}${lastName}`

  return (
    <div className="flex w-full justify-between items-center ">
      <h1 className="text-[1.5rem] sm:text-[2rem] ">{title}</h1>
      <button
        onClick={handleToggle}
        type="button"
        className="text-[#AFAFAF] text-[1.5rem] md:hidden order-3 "
      >
        <RxHamburgerMenu />
      </button>

      <div className="flex items-center gap-x-[1rem] ">
        <div className="flex items-center gap-x-[0.75rem]  rounded-full w-fit h-fit bg-[#a4ddf7] p-2 text-white font-medium ">
          <h2 className="text-[1.5rem] font-bold font-playfair ">{userName}</h2>
        </div>
      </div>
    </div>
  )
}

export default Header
