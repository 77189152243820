import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "../../common/services/httpMethod/httpMethod"
import { api } from "../../common/services/api/api"

export const getAllCountriesRequest = createAsyncThunk(
  "all/countries/request",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getRequest(api.allCountries)

      const countryList = response?.data
        ?.map((country) => ({
          id: country.cca2,
          name: country.name.common,
          value: country.name.common,
        }))
        ?.sort((a, b) => a?.name?.localeCompare(b?.name))
      return countryList
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)
