import { createAsyncThunk } from "@reduxjs/toolkit"
import { postRequest } from "../../common/services/httpMethod/httpMethod"
import { api } from "../../common/services/api/api"

export const getQuoteRequest = createAsyncThunk(
  "quote/request",
  async (values, { rejectWithValue }) => {
    try {
      const data = {
        departure: values.departure,
        destination: values.destination,
        weight: values.weight,
        email: values.email,
        shipment_date: values.shipment_date,
        get_quote_type: "international",
        items_quantity: "0",
        lenght: "0",
        height: "0",
        width: "0",
        isFragile: "0",
        isLiquid: "0",
        delivery_address: "-",
        description: "-",
        item_images: "-",
        
      }
      const response = await postRequest(api.getQuotesCreate, values)

      return response?.data?.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)
