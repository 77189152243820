import { jwtDecode } from "jwt-decode"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { logout } from "../../../Redux/slices/authSlice"
import { Toast } from "../../../components/toastify"
import { loginPathName } from "../../../components/routes/pathName"

const useAuthCheck = () => {
  const token = sessionStorage.getItem("token")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token)
        const isTokenExpired = decodedToken.exp * 1000 < Date.now()

        if (isTokenExpired) {
          localStorage.removeItem("token")
          dispatch(logout())
          navigate(loginPathName)
          Toast("error", "Session expired, please login")
        }
      } catch (error) {
        console.log("Error decoding token:", error)
      }
    }
  }, [token, dispatch, navigate])

  return !!token
}

export default useAuthCheck
