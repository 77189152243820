const Select = ({
  children,
  type,
  selectName,
  optionText,
  className,
  htmlFor,
  label,
  pText,
  id,
  selectValue,
  datas,
  divClassName,
  img,
  conClassName,
  onChange,
  onBlur,
  errors,
  touched,
  ...props
}) => {
  return (
    
    <div className="relative">
      <div className={`w-full py-[20px] ${divClassName}`}>
        {pText && (
          <p className="text-[#979797] pb-[10px]  text-[16px] font-[montserrat] leading-[23.05px]">
            {pText}
          </p>
        )}

        {
          <div
            className={`py-[12px] px-[15px] w-full rounded-[10.39px] bg-white ${
              img && "flex items-center"
            } ${conClassName}`}
          >
            <div className="w-full">
              <label
                htmlFor={htmlFor}
                className="text-[10.85px] text-[#5d5d5d]"
              >
                {label}
              </label>

              <select
                name={selectName}
                value={selectValue}
                id={id}
                className={`w-[100%] text-[16.27px] outline-none text-[#777777] ${className}`}
                onChange={onChange}
                onBlur={onBlur}
              >
                <option value="" className="text-[#777777]">
                  Select
                </option>
                {datas?.map((data) => {
                  return (
                    <option key={data.id} value={data.value}>
                      {data.name}
                    </option>
                  )
                })}
              </select>
            </div>
            {img && <img src={img} alt="" />}
          </div>
        }
      </div>
      {errors && touched && (
        <small className="text-red-500 absolute bottom-0">{errors}</small>
      )}
    </div>
  )
}

export default Select
