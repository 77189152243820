const ShipmentCard = ({ status, value, bg = "bg-red-400", index }) => {
  return (
    <div className={` ${index === 2 && "col-span-2"} md:col-span-1 h-[13rem]  p-[1.5rem] ${bg} rounded-[1.1rem] `}>
      <div
        className="flex flex-col h-full justify-between "
      >
        <p className="w-[7rem] text-[1.2rem] text-[#212121] ">{status}</p>
        <h2 className="text-[2.8rem] font-medium ">{value}</h2>
      </div>
    </div>
  )
}

export default ShipmentCard
