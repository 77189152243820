import { useEffect, useState, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { Toast } from "../../components/toastify"
import { loginPathName } from "../../components/routes/pathName"
import useModalHooks from "../../common/hooks"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { verifyOtp } from "../../Redux/action/otpAction"

const useVerify = () => {
  const dispatch = useDispatch()
  const { otp } = useSelector((state) => state.otp)
  const { modal, handleModal } = useModalHooks()
  const navigate = useNavigate()
  const [countdown, setCountdown] = useState(2)

  const closeModalAndRedirect = useCallback(() => {
    handleModal()
    navigate(loginPathName)
  }, [handleModal, navigate])

  async function handleVerifyOtp(otp, setSubmitting) {
    try {
      await dispatch(verifyOtp(otp)).unwrap()
      handleModal()
      sessionStorage.removeItem("email")
    } catch (error) {
      Toast("error", error?.message)
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    if (modal) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer)
            closeModalAndRedirect()
            return 0
          }
          return prevCountdown - 1
        })
      }, 3000)

      return () => clearInterval(timer)
    }
  }, [modal, closeModalAndRedirect])

  const { values, handleSubmit, setFieldValue, isSubmitting, errors, touched } =
    useFormik({
      initialValues: { otp: otp },
      onSubmit: async (values, { setSubmitting }) => {
        handleVerifyOtp(values.otp, setSubmitting)
      },
      validationSchema: Yup.object().shape({
        otp: Yup.string()
          .required("OTP is required")
          .length(6, "OTP must be 6 digits"),
      }),
    })

  function fieldValues(otp) {
    setFieldValue("otp", otp)
  }

  return {
    values,
    handleSubmit,
    modal,
    fieldValues,
    isSubmitting,
    errors,
    touched,
    countdown,
  }
}

export default useVerify
