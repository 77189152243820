import React from "react"

const SideIcon = ({ fill }) => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.69376 0.738693C11.1333 -0.246231 13.0298 -0.246231 14.4694 0.738693L22.3224 6.11181C23.4743 6.9 24.1631 8.20587 24.1631 9.60169V19.7715C24.1631 22.1068 22.2699 24 19.9346 24H4.22854C1.89318 24 0 22.1068 0 19.7715V9.60169C0 8.20587 0.688794 6.9 1.84076 6.11181L9.69376 0.738693ZM9.06117 18.5633C8.72755 18.5633 8.45709 18.8338 8.45709 19.1674C8.45709 19.501 8.72755 19.7715 9.06117 19.7715H15.1019C15.4355 19.7715 15.706 19.501 15.706 19.1674C15.706 18.8338 15.4355 18.5633 15.1019 18.5633H9.06117Z"
        fill={fill}
      />
    </svg>
  )
}

export default SideIcon
