import * as Yup from "yup"

export const getQuoteSchema = Yup.object({
  get_quote_type: Yup.string().optional("The quote type is required"),
  email: Yup.string().required("Email address is needed"),
  departure: Yup.string().required("Departure is required"),
  destination: Yup.string().required("Destination is required"),
  delivery_address: Yup.string().optional("Delivery Address is required"),
  isFragile: Yup.string().optional("Let us know if this a fragile item"),
  isLiquid: Yup.string().optional("Is this a liquid item"),
  description: Yup.string().optional(
    "Kindly tell us about this items you are sending"
  ),
  items_quantity: Yup.number()
    .positive()
    .integer()
    .optional("Items Quantity is required"),
  shipment_date: Yup.string().optional("Date of this shipment is required"),
  lenght: Yup.number()
    .positive()
    .integer()
    .optional("Approx. Length of the item is required"),
  height: Yup.number()
    .positive()
    .integer()
    .optional("Approx. Height of the item is required"),
  width: Yup.number()
    .positive()
    .integer()
    .optional("Approx. Width of the item is required"),
  weight: Yup.number()
    .positive()
    .integer()
    .required("Approx. Weight of the item is required"),
  //   shipment_date: Yup,
})
