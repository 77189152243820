import OTPInput from "react-otp-input"
import InputField from "../../../components/shared-components/Input"
import { usePassword } from "../../../common/eyeToggle"
import { PiEyeSlashThin, PiEyeThin } from "react-icons/pi"

const RenderInput = (
  key,
  value,
  handleOtpChange,
  handleChange,
  handleBlur,
  touched,
  errors
) => {
  const { visibility, togglePasswordVisibility } = usePassword()
  if (key === "otp") {
    return (
      <div className="relative">
        <OTPInput
          value={value}
          onChange={handleOtpChange}
          numInputs={6}
          renderSeparator={<span> </span>}
          renderInput={(props) => (
            <input
              {...props}
              style={{
                width: "3rem",
                height: "3rem",
                margin: "0 auto",
                borderRadius: "0.5rem",
                textAlign: "center",
                outline: "none",
                border: "1px solid grey",
                backgroundColor: "#F7F7F7",
              }}
            />
          )}
        />
         {touched[key] && errors[key] && (
              <small className="text-red-500 absolute left-0 -bottom-5">
                {errors[key]}
              </small>
            )}
      </div>
    )
  } else {
    return (
      <div className="relative">
        <InputField
          placeholder={
            key === "password" ? "Enter your password" : "Confirm password"
          }
          className={"bg-[#F7F7F7] "}
          type={visibility.password ? "text" : "password"}
          name={key}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors[key]}
          touched={touched[key]}
        />
        <div
          className="absolute top-5 right-2 cursor-pointer text-[1.5rem]"
          onClick={() => togglePasswordVisibility("password")}
        >
          {visibility.password ? <PiEyeThin /> : <PiEyeSlashThin />}
        </div>
      </div>
    )
  }
}

export default RenderInput
