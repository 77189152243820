import { createAsyncThunk } from "@reduxjs/toolkit"
import { patchRequest } from "../../common/services/httpMethod/httpMethod"
import { api } from "../../common/services/api/api"

export const changePassword = createAsyncThunk(
  "change/password",
  async (value, { rejectWithValue }) => {
    try {
      const response = await patchRequest(api.customerChangePassword, value)

      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)
