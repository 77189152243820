import React from "react"
import login from "../../assets/images/login.png"
import LoginForm from "./loginForm"
import PageLayout from "../../common/pageLayout"

const Login = ({ images }) => {
  return (
    <PageLayout images={login}>
      <LoginForm />
    </PageLayout>
  )
}

export default Login
