import * as Yup from "yup"

export const signUpSchema = Yup.object({
  registration_type: Yup.string().required("Registration type is required"),
  firstname: Yup.string().required("First name is required"),
  lastname: Yup.string().required("Last name is required"),
  email_address: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  phone_number: Yup.string().required("Phone number is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .required("Password is required"),
})
