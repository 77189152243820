import React from "react";
import back from "../../../assets/images/back.svg";
import { Link } from "react-router-dom";

const Back = () => {
  return (
    <Link to={-1}>
      <img src={back} alt="" />
    </Link>
  );
};

export default Back;
