import React from 'react'

const BlogCard = ({images, title, content, date}) => {
  return (
    <div className='flex flex-col items-center max-w-[354px] shadow-xl rounded-lg'>
        <div className=''>
            <img src={images} alt='blog1'/>
        </div>
        <div className='p-3 space-y-3 text-[#2C2C2C]'>
            <p className='font-semibold'>{title}</p>
            <p className=''>{content}</p>
            <p className=''>{date}</p>
        </div>

    </div>
  )
}

export default BlogCard