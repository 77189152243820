import { useFormik } from "formik"
import { Toast } from "../../components/toastify"
import { useDispatch, useSelector } from "react-redux"
import { changePassword } from "../../Redux/action/changePasswordAction"

const useSecurity = () => {
  const dispatch = useDispatch()
  const { initialValues } = useSelector((state) => state.changePassword)

  async function submit(values, setSubmitting, resetForm) {
    try {
      const response = await dispatch(changePassword(values)).unwrap()
      Toast("success", response?.message)
      resetForm()
    } catch (error) {
      Toast("error", error?.message)
    } finally {
      setSubmitting(false)
    }
  }

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    errors,
    touched,
  } = useFormik({
    initialValues,
    // validationSchema: securitySchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const { confirm_new_password, ...rest } = values
      submit(rest, setSubmitting, resetForm)
    },
  })

  return {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    errors,
    touched,
  }
}

export default useSecurity
