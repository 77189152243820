import { createAsyncThunk } from "@reduxjs/toolkit"
import { postRequest } from "../../common/services/httpMethod/httpMethod"
import { api } from "../../common/services/api/api"

export const signUpRequest = createAsyncThunk(
  "auth/signup",
  async (value, { rejectWithValue }) => {
    try {
      const response = await postRequest(api.customerCreate, value)

      return response?.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)
