import { createContext, useContext, useState } from "react"

const ToggleThrough = createContext()

export const useToggleThrough = () => {
  return useContext(ToggleThrough)
}

const GlobalHooks = ({ children }) => {
  const [selected, setSelected] = useState(null)
  const [toggleModal, setToggleModal] = useState({
    getQuote: false,
    lodgeShipment: false
    // add more if needed
  })

  function handleToggleModal(value) {
    setToggleModal((prevSelected) => ({
      ...prevSelected,
      [value]: !prevSelected[value],
    }))
  }

  return (
    <>
      <ToggleThrough.Provider
        value={{ selected, setSelected, toggleModal, handleToggleModal }}
      >
        {children}
      </ToggleThrough.Provider>
    </>
  )
}

export default GlobalHooks
