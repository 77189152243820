import React from "react"
import ModalLayout from "../../../modalLayout"
import { useSelector } from "react-redux"

const GetQuoteModal = () => {
  const { quoteResult } = useSelector((state) => state.getQuote)

  const textStyle =
    "text-gray-600 md:text-[1.5rem]  italic font-semibold"
  return (
    <ModalLayout modalToClose={"getQuote"}>
      <div className="flex flex-wrap justify-between w-[25rem] bg-gray-200 p-2 rounded-md">
        <div className="space-y-3 w-1/2  p-2 ">
          <h1 className={textStyle}>Estimated Price</h1>
          <p className="">
            &#8358;{quoteResult?.estimated_price?.toLocaleString()}
          </p>
        </div>
        <div className="space-y-3 w-1/2  p-2 ">
          <h2 className={textStyle}>Total</h2>
          <p className="">
            &#8358;{quoteResult?.total_price?.toLocaleString()}
          </p>
        </div>
        <div className="space-y-3 w-1/2  p-2 ">
          <h3 className={textStyle}>VAT</h3>
          <p className="">&#8358;{quoteResult?.vat?.toLocaleString()}</p>
        </div>
      </div>
    </ModalLayout>
  )
}

export default GetQuoteModal
