import { createAsyncThunk } from "@reduxjs/toolkit"
import { postRequest } from "../../common/services/httpMethod/httpMethod"
import { api } from "../../common/services/api/api"

export const customerLoginRequest = createAsyncThunk(
  "auth/customerLogin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await postRequest(api.customerLogin, data)
      const token = response?.headers?.authorization
      sessionStorage.setItem("token", token)
      return response?.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)
