import { createAsyncThunk } from "@reduxjs/toolkit"
import { postRequest } from "../../common/services/httpMethod/httpMethod"
import { api } from "../../common/services/api/api"

export const lodgeShipmentRequest = createAsyncThunk(
  "lodge/shipment",
  async (values, { rejectWithValue }) => {
    try {
      const data = {
        shipment_type: "international",
        shipment_item_worth: "0",
        shipping_quantity: "0",
        height: "0",
        width: "0",
        isFragile: "0",
        isLiquid: "0",
        sender_name: values.sender_name,
        sender_email: values.sender_email,
        sender_address: values.sender_country,
        weight: values.weight,
        receiver_country: values.receiver_country,
        receiver_name: values.receiver_name,
        receiver_email: values.receiver_email,
        receiver_address: "-",
        receiver_phone: '-',
        drop_off_date: "-",

      }
      const response = await postRequest(api.lodgeShipmentCreate, data)
      return response?.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)
