import { createSlice } from "@reduxjs/toolkit"
import { resendOtp, verifyOtp } from "../action/otpAction"

const otpSlice = createSlice({
  name: "otp",
  initialState: {
    otp: "",
    error: null,
    isLoading: false,
  },
  reducers: {
    setOtp: (state, { payload }) => {
      state.otp = payload
    },
  },
  extraReducers: (builders) => {
    builders.addCase(verifyOtp.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.otp = payload
    })
    builders.addCase(verifyOtp.pending, (state) => {
      state.isLoading = true
    })
    builders.addCase(verifyOtp.rejected, (state, { payload }) => {
      state.isLoading = false
      state.error = payload.error
    })
    builders.addCase(resendOtp.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.otp = payload
    })
    builders.addCase(resendOtp.pending, (state) => {
      state.isLoading = true
    })
    builders.addCase(resendOtp.rejected, (state, { payload }) => {
      state.isLoading = false
      state.error = payload.error
    })
  },
})

export const { setOtp } = otpSlice.actions
export default otpSlice.reducer
