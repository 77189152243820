import Button from "../../../components/shared-components/Button";
import RenderInput from "../renderInput";
import { Link } from "react-router-dom";
import { loginPathName } from "../../../components/routes/pathName";
import useSignUp from "./useSignUp";

const SignUpForm = () => {
  const {
    accountTypeDetails,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
  } = useSignUp();

  return (
    <form
      className="w-full space-y-[1.2rem] " 
      onSubmit={handleSubmit}
    >
      <h1  className="text-[2.5rem] font-bold">Sign Up</h1>
     
        <div className="space-y-[1.2rem] px-[0.2rem] xl:h-[53vh] xl:overflow-y-scroll ">
          {Object.entries(values).map(([field, value], i) => (
            <div key={i} className="relative">
              {RenderInput(
                field,
                value,
                handleChange,
                accountTypeDetails,
                handleBlur,
                setFieldValue,
                errors,
                touched
              )}
            </div>
          ))}
        </div>
        <div className="mt-[1rem]">
          <p className="text-[0.9rem] w-[100%]">
            By proceeding, you agree to our{" "}
            <span className="text-primary-300 font-semibold cursor-pointer">
              Terms & Conditions
            </span>{" "}
            {/* and{" "} */}
            {/* <span className="text-primary-300 font-semibold cursor-pointer">
              Privacy policy
            </span> */}
          </p>
          <br />
        </div>
      <div className="space-y-[0.5rem] " >
        <Button type="submit" text={"Sign up"} className={"text-white w-full"} />
        <div className="">
          <p className="text-[0.9rem]">
            Already have an account?{" "}
            <Link to={loginPathName} className="text-primary-300 font-bold cursor-pointer">
              Login
            </Link>
          </p>
        </div>
      </div>
    </form>
  );
};

export default SignUpForm;
