import * as Yup from "yup"

export const NewPasswordSchema = Yup.object({
    new_password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long"),
    confirm_password: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
    otp: Yup.string()
      .required("Provide valid OTP")
      .length(6, "OTP must be 6 digits"),
  })