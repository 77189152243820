import { createSlice } from "@reduxjs/toolkit"
import { passwordResetRequest } from "../action/passwordResetAction"

const passwordResetSlice = createSlice({
  name: "passwordReset",
  initialState: {
    email_address: "",
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(passwordResetRequest.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.email = payload
    })
    builder.addCase(passwordResetRequest.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(passwordResetRequest.rejected, (state, { payload }) => {
      state.isLoading = false
      state.error = payload.error
    })
  },
})
export default passwordResetSlice.reducer
