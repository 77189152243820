import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "../../common/services/httpMethod/httpMethod"
import { api } from "../../common/services/api/api"

export const getShipmentRequest = createAsyncThunk(
  "get/shipment",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getRequest(api.lodgeShipmentGet)
      return response?.data?.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)
