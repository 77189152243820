import { BiLoaderCircle } from "react-icons/bi"

const Button = ({
  className,
  text,
  icon,
  image,
  isSubmitting,
  disabled,
  ...props
}) => {
  return (
    <button
      {...props}
      disabled={disabled}
      className={`flex justify-center text-[1rem] xl:text-[20px] font-medium py-[16px] px-[0.6rem] sm:px-[42.5px] items-center gap-5 rounded-[8px] w-fit bg-primary-300  ${className}`}
    >
      {isSubmitting ? (
        <BiLoaderCircle className="animate-spin text-[1.3rem] " />
      ) : image ? (
        <img src={image} alt="" />
      ) : icon ? (
        icon
      ) : (
        <p>{text}</p>
      )}
    </button>
  )
}

export default Button
