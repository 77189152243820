import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  patchRequest,
  postRequest,
} from "../../common/services/httpMethod/httpMethod"
import { api } from "../../common/services/api/api"

export const verifyOtp = createAsyncThunk(
  "otp/verifyOtp",
  async (otp, { rejectWithValue }) => {
    try {
      const email = sessionStorage.getItem("email")
      const response = await patchRequest(
        `${api.customerOtpVerify}/${email}/${otp}`
      )

      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const resendOtp = createAsyncThunk(
  "otp/resendOtp",
  async (_, { rejectWithValue }) => {
    try {
      const email = sessionStorage.getItem("email")
      const response = await postRequest(`${api.customerOtpResend}/${email}`)

      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)
