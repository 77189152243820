import React from "react";

const Card = ({icons, title, content}) => {
  return (
    <div className=" max-w-[354px] sm:min-h-[367px] bg-white shadow-xl rounded-xl p-[2rem] space-y-5">
      <div>
        <img src={icons} alt="worldwide"/>
      </div>
      <p className="text-[20px]">{title}</p>
      <p className="text-[16px]">{content}</p>
    </div>
  );
};

export default Card;
