export const placeholder = (field) => {
  switch (field) {
    case "email":
      return "Enter email address"
    case "departure":
      return "Departure"
    case "description":
      return "Description"
    case "items_quantity":
      return "Items Quantity"
    case "destination":
      return "City, Country or Zip code"
    case "shipment_date":
      return "Select Date"
    case "vat":
      return "Optional"
    case "item_images":
      return "Item Images"
    case "delivery_address":
          return "Delivery Address"
      case "height":
      return "Approximate Height"
      case "lenght":
      return "Approximate Lenght"
      case "width":
      return "Approximate Width"
      case "weight":
      return "Approximate Weight"
    default:
      return ""
  }
}
