export function labelName(value) {
  switch (value) {
    case "isFragile":
      return "Contains fragile items? (e.g tv, glass)"
    case "isLiquid":
      return "Contains liquid items? (e.g water, drinks)"
    case "height":
      return "Approx. Height"
    case "width":
      return "Approx. Width"
    case "weight":
      return "Approx. Weight"
    case "lenght":
      return "Approx. Length"
    case "get_quote_type":
      return "quote type"
    default:
      return value.replace("_", " ")
  }
}
