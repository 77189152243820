import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { resendOtp } from "../../Redux/action/otpAction"
import { Toast } from "../../components/toastify"

const useResendOtp = () => {
  const dispatch = useDispatch()
  const countDownTime = 5
  const [resetCountDown, setResetCountdown] = useState(countDownTime)
  const [isResendDisabled, setIsResendDisabled] = useState(true)
  const countDownRef = useRef(countDownTime)

  useEffect(() => {
    let timer

    if (resetCountDown > 0) {
      timer = setInterval(() => {
        countDownRef.current -= 1
        setResetCountdown(countDownRef.current)
      }, 1000)
    } else {
      setIsResendDisabled(false)
      clearInterval(timer)
    }

    return () => clearInterval(timer)
  }, [resetCountDown])

  const handleResendCode = async () => {
    try {
      const response = await dispatch(resendOtp()).unwrap()
      if (response) {
        countDownRef.current = countDownTime
        setResetCountdown(countDownTime)
        setIsResendDisabled(true)
      }
      Toast("success", response?.message || "OTP sent successfully")
    } catch (error) {
      Toast("error", error?.message || "Kindly signup to get an OTP sent to you")
    }
  }

  return { isResendDisabled, resetCountDown, handleResendCode }
}

export default useResendOtp
