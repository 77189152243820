import { createSlice } from "@reduxjs/toolkit"
import { getQuoteRequest } from "../action/getQuoteAction"

const getQuoteSlice = createSlice({
  name: "quote",
  initialState: {
    quote: {
      // get_quote_type: "",
      // items_quantity: "",
      // lenght: "",
      // height: "",
      // width: "",
      // isFragile: "",
      // isLiquid: "",
      departure: "",
      destination: "",
      weight: "",
      email: "",
      shipment_date: "",
      // delivery_address: "",
      // description: "",
      // item_images: "",
    },
    quoteResult: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getQuoteRequest.fulfilled, (state, { payload }) => {
      state.quoteResult = payload
      state.isLoading = false
    })
    builder.addCase(getQuoteRequest.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getQuoteRequest.rejected, (state, { payload }) => {
      state.isLoading = false
      state.error = payload.error
    })
  },
})

export default getQuoteSlice.reducer
