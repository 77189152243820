import React, { useEffect, useState } from "react"
import Table from "../../components/shared-components/Table"
import TBody from "../../components/shared-components/Table/TBody"
import { PiDotsThreeVerticalBold } from "react-icons/pi"
import EmptyData from "../shared-components/emptyComponent"
import { shipmentPathName } from "../routes/pathName"
import { useDispatch, useSelector } from "react-redux"
import { getShipmentRequest } from "../../Redux/action/getShipmentAction"
import { useNavigate } from "react-router-dom"
import TableMenu from "./TableMenu"

const ShipmentTable = ({ status, limit }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { shipment } = useSelector((state) => state.getShipment)
  

  function handleSingleShipment(shipmentId) {

    navigate(`${shipmentPathName}/${shipmentId}`)
  }

  useEffect(() => {
    async function fetchProfileData() {
      try {
        await dispatch(getShipmentRequest()).unwrap()
      } catch (error) {}
    }

    fetchProfileData()
  }, [dispatch])

  const filterShipments = (status, limit) => {
    let filteredShipments =
      status === "All"
        ? shipment
        : shipment.filter((data) => data.status === status)

    if (limit) {
      return filteredShipments?.slice(0, limit)
    }

    return filteredShipments
  }

  const result = filterShipments(status, limit)
  const [activeRow, setActiveRow] = useState(null)

  const handleToggleView = (id) => {
    setActiveRow((prevActiveRow) => (prevActiveRow === id ? null : id))
  }

  return (
    <div className="w-full overflow-x-auto ">
      {shipment?.length === 0 ? (
        <EmptyData />
      ) : (
        <Table datas={["Name", "Shipment ID", "Date", "Type", "Status", " "]}>
          <TBody
            tableDatas={result}
            renderCustomContent={(detail, index) => (
              <div className="relative">
                <div>
                  <button
                    className="text-blue-500"
                    onClick={() => handleToggleView(index)}
                  >
                    <PiDotsThreeVerticalBold />
                  </button>
                </div>
                <TableMenu
                  onClick={() => handleSingleShipment(detail.lodge_shipment_id)}
                  detail={detail}
                  setActiveRow={setActiveRow}
                  activeRow={activeRow}
                  index={index}
                  result={result}
                  handleSingleShipment={handleSingleShipment}
                />
              </div>
            )}
          />
        </Table>
      )}
    </div>
  )
}

export default ShipmentTable
