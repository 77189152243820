import React from "react"
import { fullDate } from "../../../util"

const TBody = ({ tableDatas, renderCustomContent }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case "completed":
        return "text-[#2DB933]"
      case "cancelled":
        return "text-[#FF0000]"
      case "pending":
        return "text-[#FFBB00]"
      default:
        return ""
    }
  }
  const cellStyle = "py-[1rem] text-center w-fit"
  return (
    <tbody>
      {/* {(tableDatas || [])?.map((detail, index) => (
        <tr
          key={index}
          className={`text-center ${
            index % 2 === 0 ? "bg-[#F9F9F9]" : "bg-white"
          }`}
        >
          {Object.values(detail).map((data, i) => (
            <td
              key={i}
              className={`py-[1rem] w-fit ${
                i === 5
                  ? `${getStatusColor(data)} font-semibold`
                  : "font-light text-[#2A2A2A] text-[1rem]"
              }`}
            >
              {i === 2 ? (
                <span dangerouslySetInnerHTML={{ __html: data }} />
              ) : (
                data
              )}
            </td>
          ))}
            {renderCustomContent && (
            <td className="py-[1rem] w-fit">
              {renderCustomContent(detail, index)}
            </td>
          )}
        </tr>
      ))} */}
      {(tableDatas || [])?.map((tableData, i) => (
        <tr key={i}>
          <td className={cellStyle}>{tableData.receiver_name}</td>
          <td className={cellStyle}>{tableData.waybill_no}</td>
          {/* <td className={cellStyle}>N{tableData.height}</td> */}
          <td className={cellStyle}>{fullDate(tableData.created_at)}</td>
          <td className={cellStyle}>{tableData.shipment_type}</td>
          <td
            className={`${cellStyle} font-semibold ${getStatusColor(
              tableData.status
            )}`}
          >
            {tableData.status}
          </td>
          {renderCustomContent && (
            <td className="py-[1rem] w-fit">
              {renderCustomContent(tableData, i)}
            </td>
          )}
        </tr>
      ))}
    </tbody>
  )
}

export default TBody
