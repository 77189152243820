import  { useState } from "react"

const useModalHooks = () => {
  const [modal, setModal] = useState(false)

  function handleModal() {
    setModal(!modal)
  }

  return { modal, handleModal }
}

export default useModalHooks
