const ToogleThrough = ({ h3Text, className, onClick, h3ClassName }) => {
  return (
    <>
      <div className="cursor-pointer" onClick={onClick}>
        <h3
          className={`text-[#979797] font-[400] pb-[10px] text-[16px] sm:text-[21.69px] leading-[23.05px] font-montserrat ${h3ClassName}`}
        >
          {h3Text}
        </h3>
        <h1
          className={`border-b-[7px] rounded-lg border-[#979797] ${className}`}
        >
          {" "}
        </h1>
      </div>
    </>
  );
};

export default ToogleThrough;
