import React from "react"

const Footer = ({ className }) => {
  return (
    <div className={className}>
      <p className="text-[0.75rem] md:py-[1rem] sm:text-[1rem] text-center items-center">
        Copyright &copy; {new Date().getFullYear()}. All Rights Reserved.
        &mdash; Designed with love by{" "}
        <a
          href="https://zulfahgroup.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Zulfah
        </a>
      </p>
    </div>
  )
}

export default Footer
