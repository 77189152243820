import React from "react"
import InputField from "../../components/shared-components/Input"
import Button from "../../components/shared-components/Button"
import { loginPathName } from "../../components/routes/pathName"
import { Link } from "react-router-dom"
import PasswordResetLayout from "./PasswordResetLayout"
import usePasswordReset from "./usePasswordReset"

const PasswordReset = () => {

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    isLoading
  } = usePasswordReset()

  return (
    <PasswordResetLayout
      word={true}
      instruction={"Enter your email address and we’ll send you a reset link"}
    >
      <form className="mt-[2rem] space-y-[2rem]" onSubmit={handleSubmit}>
        <InputField
          placeholder="Enter Email"
          label="Email"
          type="email"
          className={"bg-[#F7F7F7] "}
          value={values.email_address}
          name="email_address"
          onBlur={handleBlur}
          onChange={handleChange}
          errors={errors.email_address}
          touched={touched.email_address}
        />
        <div className="">
          <Button
            type="submit"
            text={"Request link"}
            className={"text-white w-full"}
            disabled={isLoading}
            isSubmitting={isSubmitting || isLoading}
          />
          <div className="mt-[1.4rem] ">
            <p className="text-[0.9rem] ">
              Was this request a mistake?{" "}
              <Link
                to={loginPathName}
                className="text-primary-300 font-bold cursor-pointer"
              >
                Back to login
              </Link>
            </p>
          </div>
        </div>
      </form>
    </PasswordResetLayout>
  )
}

export default PasswordReset
