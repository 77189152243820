
export const adminCreate = `/admin/create-admin`
export const adminLogin = `/admin/login-admin`
export const adminGetCustomers = `/admin/get-customers`

export const customerCreate = `/customer/create` //consumed
export const customerGet = `/customer/get-customer` 
export const customerLogin = `/customer/login` //consumed
export const customerOtpResend = `/customer/otp/resend`
export const customerOtpVerify = `/customer/otp/verify` //consumed
export const customerForgotPassword = `/customer/forget-password` //consumed
export const customerResetPassword = `/customer/reset-password` //consumed
export const customerChangePassword = `/customer/change-password` //consumed
export const customerUpdateCustomer = `/customer/update-customer` //consumed


export const discountCreate = `/discount/create-discount`
export const discountGetAll = `/discount/all-discounts`
export const discountUpdate = `/discount/update-discount/:discount_id`


export const getQuotesCreate = `/get-qoute/create-qoutes`
export const getQuotesAll = `/get-qoute/all-qoutes`

export const lodgeShipmentCreate = `/shipment/create/lodge/shipment` //consumed
export const lodgeShipmentGet = `/shipment/lodge/shipment`
export const lodgeSingleShipmentGet = `/shipment/lodge/single/shipment`
export const lodgeShipmentUpdate = `/shipment/update/lodge/shipment/:lodge_shipment_id`

export const quotePriceCreate = `/qoute-price/create-qoute-Price`
export const quotePriceGet = `/qoute-price/get-qoute-price`
export const quotePriceUpdate = `/qoute-price/update-qoute-price`

export const registrationTypeCreate = `/regType/create`
export const registrationTypeGet = `/regType/get-document/:type`
export const registrationTypeUpdate = `/regType/update-regType`

export const shipmentFeeCreate = `/shipment/create/shipment/fee/:lodge_shipment_id`
export const shipmentFeeGet = `/shipment/fee/:shipment_fee_id`
export const shipmentFeeGetAll = `/shipment/fee`
export const shipmentFeeUpdate = `/shipment/update/shipment/fee/:shipment_fee_id`
export const shipmentFeeTransactionUpdate = `/shipment/update/shipment/transaction/:shipment_transaction_id`

export const usedDiscount = `/used-discount/used-discount/:lodge_shipment_id/:discount_id`

export const allCountries = "https://restcountries.com/v3.1/all"


