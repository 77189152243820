import { boolean, shipmentData } from "../../../components/data/optionData"
import Button from "../../../components/shared-components/Button"
import InputFields from "../../../components/shared-components/input-fields"
import Select from "../../../components/shared-components/select/Select"
import { labelName } from "../../../util/shipmentLabelName"
import UseShipmentDetails from "../apiCall/useShipmentDetails"
import { countryData } from "../../../components/data/optionData"

const ShipmentDetails = ({ setSelected }) => {
  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    isSubmitting,
    isLoading,
    countries,
  } = UseShipmentDetails()

  function numberField(field) {
    if (
      field === "shipment_item_worth" ||
      field === "shipping_quantity" ||
      field === "lenght" ||
      field === "height" ||
      field === "width" ||
      field === "weight"
    )
      return "number"
    else if (field === "drop_off_date") return "date"
    return "text"
  }

  function selectDataType(value) {
    if (value === "shipment_type") {
      return shipmentData
    } else if (value === "receiver_country" || value === "sender_country") {
  
      return countryData
    }
    return boolean
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
          {Object.entries(values)?.map(([key, value], i) => (
            <div key={i} className="w-full relative ">
              {
              key === "shipment_type" ||
              key === "isFragile" ||
              key === "isLiquid" ||
              key === "receiver_country" || key === "sender_country" ? (
                <Select
                  selectName={key}
                  htmlFor={""}
                  label={labelName(key)}
                  selectValue={value}
                  datas={selectDataType(key)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors[key]}
                  touched={touched[key]}
                />
              ) : (
                <InputFields
                  htmlFor={key}
                  id={key}
                  label={
                    key === "isFragile"
                      ? "Does this contain fragile item(e.g tv, glass)"
                      : key === "isLiquid"
                      ? "Does this contain liquid item(e.g water, drinks)"
                      : key === "height"
                      ? "Approx. Height"
                      : key === "width"
                      ? "Approx. Width"
                      : key === "weight"
                      ? "Approx. Weight(in Kg)"
                      : key === "lenght"
                      ? "Approx. Length"
                      : key.replaceAll("_", " ")
                  }
                  type={numberField(key)}
                  name={key}
                  className={``}
                  divClassName={""}
                  placeholder={
                    key === "discount_code" &&
                    "Do you have a discount code (Optional)?"
                  }
                  pText={""}
                  value={value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors[key]}
                  touched={touched[key]}
                />
              )}
            </div>
          ))}
        </div>

        <div className="flex gap-[20px] pb-[40px] w-full">
          <div className="1/2">
            <br />
            <Button
              text={"Proceed"}
              className={"text-white !w-full "}
              type="submit"
              isSubmitting={isSubmitting || isLoading}
              disabled={isLoading}
            
            />
          </div>

          <div className="1/2">
            <br />
            <Button
              onClick={() => setSelected(null)}
              type="button"
              text={"Cancel"}
              className={"  !text-[#FF2424] !bg-[#FFD4D4] "}
            />
          </div>
        </div>
      </form>
    </>
  )
}

export default ShipmentDetails
