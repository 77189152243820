import Button from "../../components/shared-components/Button"
import RenderSecurity from "./renderSecurity"
import useSecurity from "./useSecurity"

const Security = () => {
  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    errors,
    touched,
  } = useSecurity()

  return (
    <div className="">
      <form
        className="grid grid-cols-1 lg:grid-cols-2 gap-4 items-end w-full"
        onSubmit={handleSubmit}
      >
        {Object.entries(values)?.map(([field, value], i) => (
          <div key={i}>
            {RenderSecurity(
              field,
              value,
              handleChange,
              handleBlur,
              errors,
              touched
            )}
          </div>
        ))}
        <div>
          <Button
            type="submit"
            disabled={isSubmitting}
            isSubmitting={isSubmitting}
            text={"Change password"}
            className={"w-full text-white "}
          />
        </div>
      </form>
    </div>
  )
}

export default Security
