import React, { useState } from "react"
import ProfileInput from "./ProfileInput"
import { PiEyeSlashThin, PiEyeThin } from "react-icons/pi"

const RenderSecurity = (
  field,
  value,
  handleChange,
  handleBlur,
  errors,
  touched
) => {
  const [showPassword, setShowPassword] = useState(false)

  function togglePasswordVisibility() {
    setShowPassword(!showPassword)
  }

  return (
    <div className="relative">
      <ProfileInput
        type={showPassword ? "text" : "password"}
        placeholder={field.replaceAll("_", " ")}
        value={value}
        name={field}
        label={field.replaceAll("_", " ")}
        onChange={handleChange}
        onBlur={handleBlur}
        className="bg-[#F7F7F7] lg:bg-white"
        errors={errors[field]}
        touched={touched[field]}
      />
      <div
        className="absolute top-5 right-2 cursor-pointer text-[1.5rem]"
        onClick={togglePasswordVisibility}
      >
        {showPassword ? <PiEyeThin /> : <PiEyeSlashThin />}
      </div>
    </div>
  )
}

export default RenderSecurity
