export function fullDate(date) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const formattedDate = new Date(date)
  const month = monthNames[formattedDate.getMonth()]
  const allDate = `${month} ${formattedDate.getDate()}, ${formattedDate.getFullYear()}`
  return allDate
}
