import React from "react"
import InputField from "../../components/shared-components/Input"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { usePassword } from "../../common/eyeToggle"
import { PiEyeSlashThin, PiEyeThin } from "react-icons/pi"

const commonInputStyles = {
  containerStyle: {
    width: "100%",
  },
  inputStyle: {
    height: "2.7rem",
    backgroundColor: "white",
    paddingTop: "2rem",
    paddingBottom: "1.5rem",
    paddingLeft: "3.5rem",
    paddingRight: "1.5rem",
    width: "100%",
    borderRadius: "0.5rem",
    outline: "none",
    border: "none",
  },
  buttonStyle: {
    backgroundColor: "white",
    border: "none",
    outline: "none",
    width: "10%",
  },
}

const RenderInput = (
  field,
  value,
  handleChange,
  accountTypeDetails,
  handleBlur,
  setFieldValue,
  errors,
  touched
) => {
  const { visibility, togglePasswordVisibility } = usePassword()
  if (field === "registration_type") {
    return (
      <div className="relative">
        <select
          value={value}
          name={field}
          onChange={handleChange}
          onBlur={handleBlur}
          className="w-full rounded-[0.4rem] bg-[#F9F9F9] pt-[1.5rem] pb-[0.5rem] px-[1rem] border border-white  outline-none"
        >
          <option value="">Select account type...</option>
          {accountTypeDetails.map((data, i) => (
            <option key={i} value={data}>
              {data}
            </option>
          ))}
        </select>
        {touched[field] && errors[field] && (
          <small className="text-red-500 absolute left-0 -bottom-5">
            {errors[field]}
          </small>
        )}
      </div>
    )
  } else if (field === "phone_number") {
    return (
      <div className="relative">
        <PhoneInput
          country={"ng"}
          value={value}
          onChange={(phone_number) =>
            setFieldValue("phone_number", phone_number)
          }
          onBlur={handleBlur}
          inputProps={{
            name: field,
            required: true,
          }}
          containerStyle={commonInputStyles.containerStyle}
          inputStyle={commonInputStyles.inputStyle}
          buttonStyle={commonInputStyles.buttonStyle}
        />
        {touched[field] && errors[field] && (
          <small className="text-red-500 absolute left-0 -bottom-5">
            {errors[field]}
          </small>
        )}
      </div>
    )
  } else {
    return (
      <div className="relative">
        <InputField
          type={
            field === "password"
              ? visibility.password
                ? "text"
                : "password"
              : "text"
          }
          placeholder={field.replaceAll("_", " ")}
          value={value}
          name={field}
          onChange={handleChange}
          onBlur={handleBlur}
          className="bg-[#F7F7F7] lg:bg-white"
        />
        {field === "password" && (
          <div
            className="absolute top-5 right-2 cursor-pointer text-[1.5rem]"
            onClick={() => togglePasswordVisibility("password")}
          >
            {visibility.password ? <PiEyeThin /> : <PiEyeSlashThin />}
          </div>
        )}
        {touched[field] && errors[field] && (
          <small className="text-red-500 absolute left-0 -bottom-5">
            {errors[field]}
          </small>
        )}
      </div>
    )
  }
}

export default RenderInput
