import { createSlice } from "@reduxjs/toolkit"
import { signUpRequest } from "../action/signUpAction"

const signUpSlice = createSlice({
  name: "signUp",
  initialState: {
    signUpData: null,
    initialValues: {
      registration_type: "",
      firstname: "",
      lastname: "",
      email_address: "",
      phone_number: "",
      password: "",
    },
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signUpRequest.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.signUpData = payload
    })
    builder.addCase(signUpRequest.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(signUpRequest.rejected, (state, { payload }) => {
      state.isLoading = false
      state.error = payload.error
    })
  },
})

export default signUpSlice.reducer
