import React, { useRef } from "react";
import useClickOut from "../../util/useClickOut";

const TableMenu = ({
  onClick,
  setActiveRow,
  activeRow,
  index,
  result = [],
}) => {
  const menuRef = useRef();

  useClickOut(menuRef, () => setActiveRow(null));

  return (
    <ul
      // ref={menuRef}
      className={`bg-white z-20 text-[#4A515C] text-[0.75rem] space-y-3 ${
        activeRow === index ? "block" : "hidden"
      } text-center w-[8rem] p-[0.8rem] rounded-[0.8rem] border shadow-xl ${
        result.length - 1 === index || result.length - 2 === index
          ? "-bottom-4"
          : "top-6"
      } absolute left-[-6rem]`}
    >
      <li
        className="cursor-pointer"
        onClick={(event) => {
          event.stopPropagation(); 
          onClick();
        }}
      >
        View
      </li>
      <li
        className="cursor-pointer"
        onClick={(event) => {
          event.stopPropagation();
          setActiveRow(null);
        }}
      >
        Cancel
      </li>
    </ul>
  );
};

export default TableMenu;
