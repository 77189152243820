import React from "react"
import empty from "../../../assets/images/Empty.png"

const EmptyData = ({ message = "There are currently no shipments" }) => {
  return (
    <div className="w-full flex flex-col items-center justify-center gap-y-5 my-2  py-[5rem] text-center font-gothic bg-[#EFFAFF] rounded-[0.5rem] ">
      <div className="">
        <img src={empty} alt="" className="max-w-full " />
      </div>
      <h1 className="w-[9rem] ">{message}</h1>
    </div>
  )
}

export default EmptyData
