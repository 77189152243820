export const loginPathName = "/login"
export const dashboardPathName = "/dashboard"
export const signUpPathName = "/sign-up"
export const shipmentPathName = "/shipment"
export const settingsPathName = "/settings"
export const singleShipmentPathName = `${shipmentPathName}/:id`
export const landingPathName = "/"
export const sendingParcels = "/sending-parcels"
export const verifyPathName = "/verify"
export const passwordResetPathName = "/password-reset"
export const newPasswordResetPathName = "/input-new-password"
