import * as Yup from "yup"

export const ShipmentDetailsSchema = Yup.object({
  shipment_type: Yup.string().optional("Shipment Type is required."),
  shipment_item_worth: Yup.number()
    .positive()
    .optional("Shipment Item Worth is required.")
    .min(1),
  shipping_quantity: Yup.number()
    .positive()
    .integer()
    .optional("Shipping Quantity is required.")
    .min(1),
  sender_address: Yup.string().optional("Sender Address is required."),
  sender_name: Yup.string().required("Sender Name is required."),
  sender_email: Yup.string().email().required("Sender Email is required."),
  receiver_country: Yup.string().required("Receiver Country is required."),
  receiver_name: Yup.string().required("Receiver Name is required."),
  receiver_address: Yup.string().optional("Receiver Address is required."),
  receiver_email: Yup.string().email().required("Receiver Email is required."),
  receiver_phone: Yup.string().optional("Receiver Phone is required."),
  drop_off_date: Yup.date().optional("Drop Off Date is required."),
  // waybill_no: Yup.string().required("Waybill Number is required."),
  lenght: Yup.number()
    .positive()
    .optional("An Approximate Length is required.")
    .min(1),
  height: Yup.number()
    .positive()
    .optional("An Approximate height is required")
    .min(1),
  width: Yup.number()
    .positive()
    .optional("An Approximate width is required.")
    .min(1),
  weight: Yup.number()
    .positive()
    .required("An Approximate weight is required.")
    .min(1),
  isFragile: Yup.string().optional(
    "Kindly confirm if this item is fragile e.g tv, glass."
  ),
  isLiquid: Yup.string().optional(
    "Kindly confirm if this item contains liquid e.g water, drinks."
  ),
  discount_code: Yup.string().optional(),
})
