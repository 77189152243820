import React from "react"
import GetQuote from "../../components/shared-components/getQuote"
import SendingParcels from "../SendingParcel"
import { useToggleThrough } from "../../Hooks/GlobalHook"
import useDashboard from "./useDashboard"
import ShipmentTable from "../../components/shipmentTable"
import { Link } from "react-router-dom"
import { shipmentPathName } from "../../components/routes/pathName"

const Dashboard = () => {
  const { selected } = useToggleThrough()
  const { customer } = useDashboard()

  return (
    <>
      <div className="">
        <h1 className="text-[1.25rem] font-Raleway ">
          Welcome, <strong>{customer?.firstname } {customer?.lastname }</strong>
        </h1>
        <div className="mt-[1.13rem] ">
          <GetQuote />
        </div>
        <div className="mt-[1.9rem]  ">
          <div className="flex justify-between text-[1.25rem] text-black font-normal">
            <span>Recent Shipments</span>
            <Link to={shipmentPathName}>
              <button
                type="button"
                className="text-primary-300 underline cursor-pointer"
              >
                See All
              </button>
            </Link>
          </div>
          <div className="overflow-x-auto">
            <ShipmentTable status={"All"} limit={5} />
          </div>
        </div>
      </div>
      {selected !== null && <SendingParcels />}
    </>
  )
}

export default Dashboard
