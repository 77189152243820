import { useState } from "react"

export const usePassword = () => {
  const [visibility, setVisibility] = useState({
    password: "",
    confirmPassword: "",
  })

  function togglePasswordVisibility(value) {
    setVisibility((prevShowPassword) => ({
      ...prevShowPassword,
      [value]: !prevShowPassword[value],
    }))
  }

  return { visibility, togglePasswordVisibility }
}
