import Button from "../Button"
import ShipmentCard from "./shipmentCard"
import packag from "../../../assets/images/package.png"
import { useMemo } from "react"
import renderInput from "./quoteInput/renderInput"
import Select from "../../../components/shared-components/select/Select"
import { labelName } from "../../../util/shipmentLabelName"

import useDashboard from "../../../pages/Dashboard/useDashboard"
import GetQuoteModal from "./getQuoteModal"
import useQuote from "./useQuote"
import { boolean, shipmentData } from "../../../components/data/optionData"
import { countryData } from "../../../components/data/optionData"
import InputFields from "../../../components/shared-components/input-fields"

const GetQuote = () => {
  const { shipment } = useDashboard()
  const {
    toggleModal,
    setSelected,
    selectOptions,
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    isSubmitting,
  } = useQuote()

  const shipmentStatus = useMemo(
    () => (status) => {
      return shipment?.filter((data) => data.status === status)?.length || 0
    },
    [shipment]
  )

  const details = useMemo(
    () => [
      {
        status: "Pending shipments",
        value: shipmentStatus("pending"),
        bg: "bg-secondary-100",
      },
      {
        status: "Delivered shipments",
        value: shipmentStatus("completed"),
        bg: "bg-primary-100",
      },
      {
        status: "Cancelled shipments",
        value: shipmentStatus("cancelled"),
        bg: "bg-primary-200",
      },
    ],
    [shipmentStatus]
  )
  function selectDataType(value) {
    if (value === "shipment_type") {
      return shipmentData
    } else if (value === "destination" || value === "departure") {
  
      return countryData
    }
    return boolean
  }

  function numberField(field) {
    if (
      field === "shipment_item_worth" ||
      field === "shipping_quantity" ||
      field === "lenght" ||
      field === "height" ||
      field === "width" ||
      field === "weight"
    )
      return "number"
    else if (field === "shipment_date") return "date"
    return "text"
  }

  return (
    <>
      <div className="xl:flex  w-full gap-[1.5rem] space-y-[1.8rem] xl:space-y-0">
        <div className="w-full xl:w-[60%] flex flex-col justify-between gap-y-[1rem]">
          <div className="xl:flex justify-between items-start w-full rounded-[1.1rem] py-[1.5rem] px-[1.5rem] md:px-[2.5rem] bg-primary-300">
            <div>
              <h1 className="text-[2rem] xl:text-[2.3rem] text-white font-medium w-[19rem] md:w-[22rem]">
                Seamless logistics starts here
              </h1>
              <div className="mt-[1.4rem] xl:mt-[3rem]">
                <Button
                  text={"Lodge Shipment"}
                  className={"text-primary-300 bg-white"}
                  onClick={() => setSelected(1)}
                />
              </div>
            </div>
            <div className="flex justify-end mt-[1rem]">
              <img src={packag} alt="package" className="max-w-full" />
            </div>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-3 gap-[1rem]">
            {details.map((item, index) => (
              <ShipmentCard
                key={index}
                status={item.status}
                value={item.value}
                bg={item.bg}
                index={index}
              />
            ))}
          </div>
        </div>

        <div className="w-full xl:w-[40%] rounded-[1.1rem] py-[1.3rem] px-[1.2rem] md:px-[2.3rem] bg-primary-100">
          <h2 className="text-[2rem] text-black font-medium">
            Get an Instant Quotes
          </h2>
          <form onSubmit={handleSubmit} className="mt-[0.9rem]">
            <div className="h-[50vh] overflow-y-scroll">
              
              {Object.entries(values).map(([key, value], i) => (
                 <div key={i} className="w-full relative ">
                 {
                    key === "shipment_type" ||
                    key === "isFragile" ||
                    key === "isLiquid" ||
                    key === "departure" || key === "destination" ? (
                      <Select
                        selectName={key}
                        htmlFor={""}
                        label={labelName(key)}
                        selectValue={value}
                        datas={selectDataType(key)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={errors[key]}
                        touched={touched[key]}
                      />
                    ) : (
                      <InputFields
                        htmlFor={key}
                        id={key}
                        label={
                          key === "isFragile"
                            ? "Does this contain fragile item(e.g tv, glass)"
                            : key === "isLiquid"
                            ? "Does this contain liquid item(e.g water, drinks)"
                            : key === "height"
                            ? "Approx. Height"
                            : key === "width"
                            ? "Approx. Width"
                            : key === "weight"
                            ? "Approx. Weight(in Kg)"
                            : key === "lenght"
                            ? "Approx. Length"
                            : key.replaceAll("_", " ")
                        }
                        type={numberField(key)}
                        name={key}
                        className={``}
                        divClassName={""}
                        placeholder={
                          key === "discount_code" &&
                          "Do you have a discount code (Optional)?"
                        }
                        pText={""}
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={errors[key]}
                        touched={touched[key]}
                      />
                    )
                 }
                 </div>
              ))}
            </div>
            <div className="mt-[1.4rem]">
              <Button
                isSubmitting={isSubmitting}
                disabled={isSubmitting}
                text="Get Quotes"
                className="text-white bg-primary-500"
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
      {toggleModal.getQuote && <GetQuoteModal />}
    </>
  )
}

export default GetQuote
