import React, { useEffect } from "react"
import { IoCloseCircle } from "react-icons/io5"
import { useToggleThrough } from "../../Hooks/GlobalHook"

const ModalLayout = ({ modalToClose, children }) => {
  const { handleToggleModal } = useToggleThrough()

  function closeModal(e) {
    e.target.id === "closeModal" && handleToggleModal(modalToClose)
  }

  useEffect(() => {
    if (true) {
      document.body.style.overflow = "hidden"
    }
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [])

  return (
    <div
      id="closeModal"
      onClick={closeModal}
      className="sm:bg-[#000000] bg-gray-200 flex w-full h-screen justify-center items-center sm:bg-opacity-35 fixed top-0 left-0 z-50 overflow-y-auto"
    >
      <div className="w-fit max-w-[80%] relative">
        <button
          onClick={() => handleToggleModal(modalToClose)}
          type="button"
          className="text-[2rem] text-gray-300 absolute -top-2 -right-2"
        >
          <IoCloseCircle />
        </button>
        <div className="  p-6 bg-white rounded-lg shadow-md">
          <div className="w-fit">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default ModalLayout
