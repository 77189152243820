import React from "react"
import PasswordResetLayout from "../PasswordResetLayout"
import Button from "../../../components/shared-components/Button"
import useNewPassword from "./useNewPassword"
import RenderInput from "./renderInput"

const NewPassword = () => {
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    isLoading,
    handleOtpChange,
  } = useNewPassword()

  return (
    <PasswordResetLayout
      instruction={"Enter a password you can always remember"}
    >
      <form className="mt-[2rem] space-y-[2rem]" onSubmit={handleSubmit}>
        {Object.entries(values)?.map(([key, value], i) => (
          <div key={i}>
            {RenderInput(
              key,
              value,
              handleOtpChange,
              handleChange,
              handleBlur,
              touched,
              errors
            )}
          </div>
        ))}

        <div className="">
          <Button
            type="submit"
            isSubmitting={isSubmitting || isLoading}
            disabled={isLoading}
            text={"Update password"}
            className={"text-white w-full"}
          />
        </div>
      </form>
    </PasswordResetLayout>
  )
}

export default NewPassword
