import React from "react"
import InputField from "../../components/shared-components/Input"
import { PiEyeSlashThin, PiEyeThin } from "react-icons/pi"
import { usePassword } from "../../common/eyeToggle"

const RenderInput = (
  field,
  value,
  handleChange,
  handleBlur,
  errors,
  touched
) => {
  const { visibility, togglePasswordVisibility } = usePassword()

  return (
    <div className="relative">
      <InputField
        type={
          field === "password"
            ? visibility.password
              ? "text"
              : "password"
            : "email"
        }
        placeholder={field.replace("_", " ")}
        value={value}
        name={field}
        onChange={handleChange}
        onBlur={handleBlur}
        className="bg-[#F7F7F7] lg:bg-white"
        errors={errors[field]}
        touched={touched[field]}
      />
      {field === "password" && (
        <div
          className="absolute top-5 right-2 cursor-pointer text-[1.5rem]"
          onClick={() => togglePasswordVisibility("password")}
        >
          {visibility.password ? <PiEyeThin /> : <PiEyeSlashThin />}
        </div>
      )}
    </div>
  )
}

export default RenderInput
