import React from "react"

const Table = ({datas, children }) => {
  return (
    <table className="w-full mt-[0.8rem] font-Raleway  rounded-t-lg overflow-hidden ">
      <thead className="">
        <tr className="">
          {(datas || []).map(
            (data, i) => (
              <th key={i} className="bg-primary-100 px-4 py-2 ">
                {data}
              </th>
            )
          )}
        </tr>
          </thead>
      
      {children}
    </table>
  )
}

export default Table
