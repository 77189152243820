import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "../../common/services/httpMethod/httpMethod"
import { api } from "../../common/services/api/api"

export const customerDataRequest = createAsyncThunk(
  "auth/customerData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getRequest(api.customerGet)
      return response?.data?.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)
