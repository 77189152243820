import { createSlice } from "@reduxjs/toolkit"
import { customerLoginRequest } from "../action/authAction"

const authSlice = createSlice({
  name: "auth",
  initialState: {
    initialValues: {
      email_address: "",
      password: "",
    },
    token: null,
    isLoading: false,
    isAuthenticated: !!sessionStorage.getItem("token"),
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.isLoading = false
      state.error = null
      sessionStorage.removeItem("token")
    },
  },
  extraReducers: (builders) => {
    builders.addCase(customerLoginRequest.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.token = payload.token
      state.isAuthenticated = true
    })
    builders.addCase(customerLoginRequest.pending, (state) => {
      state.isLoading = true
    })
    builders.addCase(customerLoginRequest.rejected, (state, { payload }) => {
      state.isLoading = false
      state.error = payload.error
    })
  },
})

export const { logout } = authSlice.actions
export default authSlice.reducer
