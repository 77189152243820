import React from "react"
import ProfileInput from "./ProfileInput"

const renderProfile = (
  field,
  value,
  handleChange,
  handleBlur,
  errors,
  touched
) => {
  return (
    <ProfileInput
      placeholder={field}
      value={value}
      name={field}
      type={field === "phone" ? "tel" : field === "email" ? "email" : "text"}
      onChange={handleChange}
      onBlur={handleBlur}
      label={field}
      readOnly={
        field === "email_address" ||
        field === "phone_number" ||
        field === "registration_type"
      }
      errors={errors[field]}
      touched={touched[field]}
    />
  )
}

export default renderProfile
