import { useFormik } from "formik"
import { ShipmentDetailsSchema } from "../../../common/validation/shipmentDetailsValidation"
import { Toast } from "../../../components/toastify"
import { useDispatch, useSelector } from "react-redux"
import { lodgeShipmentRequest } from "../../../Redux/action/lodgeShipmentAction"
import { getShipmentRequest } from "../../../Redux/action/getShipmentAction"
import { useEffect } from "react"
import { getAllCountriesRequest } from "../../../Redux/action/getAllCountriesAction"

const UseShipmentDetails = () => {
  const dispatch = useDispatch()
  const { initialValues, isLoading } = useSelector(
    (state) => state.lodgeShipment
  )
  const { countries, allCountries } = useSelector((state) => state.getAllCountries)
 

  useEffect(() => {
    async function getCountries() {
      await dispatch(getAllCountriesRequest()).unwrap()
    }
    getCountries()
  }, [dispatch])

  async function submit(value, setSubmitting, resetForm) {
    try {
     
      const response = await dispatch(lodgeShipmentRequest(value)).unwrap()
      await dispatch(getShipmentRequest()).unwrap()
      resetForm()
      Toast("success", response?.message)
    } catch (error) {
      Toast("error", error.message)
    } finally {
      setSubmitting(false)
    }
  }

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema: ShipmentDetailsSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      submit(values, setSubmitting, resetForm)
    },
  })

  return {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    isSubmitting,
    isLoading,
    countries,
    allCountries
  }
}

export default UseShipmentDetails
