import { CloseIcon } from "../../../assets/images";

const CloseComponent = ({onClick,className}) => {
  return (
    <>
      <img
        src={CloseIcon}
        alt="close-icon"
        className={`hidden sm:block absolute right-[70px] top-[70px] w-[43.38px] h-[43.38px] cursor-pointer ${className}`}
        onClick={onClick}
      />
    </>
  );
};

export default CloseComponent;
