import React from "react"
import facebook from "../../assets/images/facebook.svg"
import linkedin from "../../assets/images/linkedin.svg"
import twitter from "../../assets/images/twitter.svg"
import instagram from "../../assets/images/instagram.svg"
import laptop from "../../assets/images/laptop.png"
import Logo from "../../assets/images/Logo.svg"
import calendar from "../../assets/images/calender.svg"
import cargo_package from "../../assets/images/cargo_package.png"
import Navbar from "../../components/nav"
import Button from "../../components/shared-components/Button"
import Card from "../../components/shared-components/Card"
import {
  BlogDetails,
  CardDetails,
} from "../../components/shared-components/Datas"
import BlogCard from "../../components/shared-components/BlogCard"
import LandingInputField from "./component/landingInputField"
import { Link } from "react-router-dom"
import useQuote from "../../components/shared-components/getQuote/useQuote"
import GetQuoteModal from "../../components/shared-components/getQuote/getQuoteModal"
import { labelName } from "../../util/shipmentLabelName"
import { placeholder } from "../../util/placeholder"
import { countryData } from "../../components/data/optionData"

const Landing = () => {
  const {
    toggleModal,
    selectOptions,
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    isSubmitting,
  } = useQuote()

  return (
    <>
      <div>
        <div className="flex justify-center bg-primary-300 text-white font-montserrat p-5 relative">
          <div className="min-w-[90%] flex justify-between flex-col gap-5 items-start sm:items-center sm:flex-row">
            <div className="flex gap-5 items-center">
              <img
                src={facebook}
                alt="facebook"
                className="cursor-pointer"
              ></img>
              <img
                src={instagram}
                alt="instagram"
                className="cursor-pointer"
              ></img>
              <img
                src={linkedin}
                alt="linkedin"
                className="cursor-pointer"
              ></img>
              <img src={twitter} alt="twitter" className="cursor-pointer"></img>
            </div>

            <div>
              71A, Oduduwa Crescent, GRA, Ikeja, Lagos.{" "}
              <span className="px-2">|</span> +2349123167602{" "}
              <span className="px-2">|</span> +2348089887115
            </div>
          </div>
        </div>
        <div className="flex justify-center bg-logistifyBG bg-no-repeat bg-cover  bg-center text-white h-screen">
          <div className="max-w-[90%]">
            <Navbar />
            <div className="flex flex-col justify-around ">
              <div className="flex flex-col items-center text-center sm:items-start sm:text-start sm:w-[45%] sm:p-5 space-y-5 h-fit">
                <p className="text-[48px] sm:text-[73.64px] font-black leading-tight pt-[4rem] sm:pt-[2rem] ">
                  Send and receive parcels{" "}
                  <span className="font-playfair">seamlessly</span>
                </p>
                <p className="text-[20px] sm:text-[24px]">
                  Providing cost-effective and reliable global logistics
                  services
                </p>
                <Link to={"/login"}>
                  <Button
                    text="Get started"
                    className="w-[269.37px] sm:w-[356.88px] text-[29.04px] sm:text-[38.39px] px-[55.18px] py-[17.43px] sm:px-[72.94px] sm:py-[23.03px] rounded-[15.36px]"
                  />
                </Link>
              </div>
              <img
                src={laptop}
                alt="logistifly"
                className="pt-[4rem] sm:pt-0 sm:absolute sm:top-[18rem] sm:left-[22rem] max-h-screen h-fit"
              />
            </div>
          </div>
        </div>

        <div
          id=""
          className="flex justify-center items-center py-[2rem] pt-[3rem] sm:pt-[13rem] bg-white sm:h-screen sm:relative"
        >
          <div className="min-w-[90%] space-y-[2rem]  ">
            <div className="flex flex-col  space-y-5 sm:flex-row sm:items-center  sm:px-[65px] py-[24px]  sm:w-[1107px] p-5 rounded-2xl bg-primary-100 sm:absolute sm:top-[-5rem] right-[10rem]">
              <div className="flex flex-col items-center sm:items-start space-y-2 w-full sm:w-[40%] text-center sm:text-start">
                <p className="text-[32px] max-w-[232px] ">
                  Get Instant Online Quotes!
                </p>
                <p className="text-[16px] max-w-[282px]">
                  Experience seamless shipping with Shippfy. Enjoy the
                  convenience of instant freight quotes online
                </p>
              </div>
              <div className="min-w-[60%] h-[50vh] sm:h-[36vh] overflow-y-scroll ">
                <form onSubmit={handleSubmit} className="space-y-3">
                  <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 w-full">
                    {Object.entries(values).map(([field, values], index) => (
                      <div key={index} className="relative">
                        <div className="bg-white flex items-center shadow-lg rounded-[8px] p-2 ">
                          <div className="w-[90%] space-y-2">
                            <label className="text-[12px]"> {labelName(field)}</label>
                            {field === "departure" ||
                            field === "destination" ? (
                              <select
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name={field}
                                className="w-full block border-none outline-none"
                                placeholder={placeholder(field)}
                              >
                                <option value="">Select Type...</option>
                                {
                                  countryData.map((data) => (
                                  <option key={data.id} value={data.value}>
                                    {data.name}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              <LandingInputField
                                placeholder={placeholder(field)}
                                value={values}
                                name={field}
                                type={
                                  field === "shipment_date" ? "date" : "text"
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            )}
                          </div>
                          {field === "Date" && (
                            <div className="w-[10%]">
                              <img src={calendar} alt="calender" />
                            </div>
                          )}
                        </div>
                        {errors[field] && touched[field] && (
                          <small className=" absolute text-red-500">
                            {errors[field]}
                          </small>
                        )}
                      </div>
                    ))}
                    <div className="w-full">
                      <Button
                        type="submit"
                        isSubmitting={isSubmitting}
                        disabled={isSubmitting}
                        text="Get Quote"
                        className="py-[15.27px] px-[48.36px] !text-white w-[195px]  rounded-[10.18px]"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <br /><br /><br /><br /><br /><br /><br />
            <div className="flex flex-col items-center space-y-5" id="about">
              <p className="text-[25.45px] font-medium">About us</p>
              <p className="text-[16px] max-w-[70%] text-center">
                We are a global logistics company that provides end-to-end
                supply chain solutions tailored to meet the needs of our
                customers. We pride ourselves on our commitment to excellence.
                Our extensive network and advanced technology enable us to
                provide seamless, end-to-end shipping services, ensuring your
                goods reach their destination safely and on time. We are
                committed to providing our customers with the highest level of
                service and support. Our team of experts is dedicated to
                ensuring that your shipments are handled with care and delivered
                on time. We offer a wide range of services to meet your shipping
                needs, including air freight, ocean freight, trucking,
                warehousing, and distribution. Our team of experts will work
                with you to develop a customized solution that meets your
                specific requirements.
              </p>
            </div>

            <div className="flex justify-center items-center sm:mt-[20rem]">
              <div className="grid grid-col-1 sm:grid-cols-2 md:grid-cols-3 justify-center sm:justify-between items-center gap-5 ">
                {CardDetails.map((item, index) => (
                  <Card
                    key={index}
                    icons={item.icons}
                    title={item.title}
                    content={item.content}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        <div id="" className="flex justify-center bg-white">
          <div className="flex flex-col sm:flex-row justify-between py-[2rem] sm:flex min-w-[90%] rounded-2xl bg-primary-100 sm:px-[3rem]">
            <div className="flex flex-col order-2 text-center sm:text-start items-center sm:items-start sm:justify-center space-y-8">
              <p className="text-[30px] sm:text-[32px] max-w-[270px]">
                Premium logistics in a class of it’s own
              </p>
              <p className="flex items-center text-[16px] max-w-[334px]">
                We’re truly dedicated to serving the global trade with a
                permanent endeavor to conserve energy, protect people and
                preserve the environment thereby contributing towards global
                economic growth and development.
              </p>
              <Link to="/login">
                <Button
                  text="Get Started"
                  className="min-w-[224px] text-[25.45px] px-[44.36px] py-[15.27px]"
                />
              </Link>
            </div>
            <div className="sm:order-2 max-w-[700px]">
              <img src={cargo_package} alt="cargo_package" className="" />
            </div>
          </div>
        </div>

        <div
          id="services"
          className="flex flex-col space-y-[2rem] justify-center items-center sm:h-screen py-[3rem]"
        >
          <p className="text-[25.45px] font-medium">Our Services</p>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 items-center gap-[2rem]">
            {BlogDetails.map((item, index) => (
              <BlogCard
                key={index}
                images={item.images}
                title={item.title}
                content={item.content}
                date={item.date}
              />
            ))}
          </div>
        </div>

        <div className="flex justify-center py-[1rem]">
          <div className="flex flex-col sm:text-start sm:min-w-[1242px] gap-[3rem] items-center">
            <div className="flex flex-col py-[3rem] bg-footerBG bg-no-repeat w-full text-white bg-center bg-cover h-fit items-center rounded-[20px]">
              <div className="flex flex-col items-center gap-[2rem] sm:flex-row w-full justify-around">
                <div className="space-y-[3rem] max-w-[300px]">
                  <img src={Logo} alt="Logo" />
                  <div className="max-w-[300px] text-[12px]">
                    <p>
                      We pride ourselves on our commitment to excellence. Our
                      extensive network and advanced technology enable us to
                      provide seamless, end-to-end shipping services, ensuring
                      your goods reach their destination safely and on time.{" "}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between items-center min-w-[300px] ">
                  <span className="space-y-[2rem]">
                    <p>About</p>
                    <p>Services</p>
                  </span>
                  <span className="space-y-[2rem]">
                    <p>Contact</p>
                    <p>Privacy Policy</p>
                    <p>Terms of use</p>
                  </span>
                </div>
                <div className="space-y-[2rem] min-w-[300px]">
                  <div>
                    <h2 className="font-medium text-[32px]">Stay updated!</h2>
                    <p className="text-[12px]">
                      Get first-hand updates when we release them
                    </p>
                  </div>
                  <div className="text-black">
                    <input
                      type="text"
                      placeholder="Email"
                      className="px-[15px] py-[15px] text-sm w-full rounded-[5.66px] "
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col-reverse sm:flex-row items-center gap-y-[2rem] py-[2rem] w-full px-[5rem] justify-between">
                <div
                  id="contact"
                  className="flex justify-between max-w-[190.61px] gap-5 items-center"
                >
                  <img src={facebook} alt="facebook"></img>
                  <img src={instagram} alt="instagram"></img>
                  <img src={linkedin} alt="linkedin"></img>
                  <img src={twitter} alt="twitter"></img>
                </div>

                <div>
                  <Button
                    text="Subscribe"
                    className="py-[11.49px] px-[36.4px] max-w-[177px] rounded-[10.18px]"
                  />
                </div>
              </div>
            </div>
            <p className="flex items-center text-center ">
              COPYRIGHT &copy; {new Date().getFullYear()} ALL RIGHTS RESERVED BY
              LOGISTIFLY
            </p>
          </div>
        </div>
      </div>
      {toggleModal.getQuote && <GetQuoteModal />}
    </>
  )
}
export default Landing
