import { useNavigate } from "react-router-dom"
import { loginPathName } from "../../../components/routes/pathName"
import { Toast } from "../../../components/toastify"
import { useFormik } from "formik"
import { NewPasswordSchema } from "../../../common/validation/newPasswordValidation"
import { useDispatch, useSelector } from "react-redux"
import { validatePassword } from "../../../Redux/action/newPasswordAction"

const useNewPassword = () => {
  const dispatch = useDispatch()
  const { initialValues, isLoading } = useSelector((state) => state.newPassword)
  const navigate = useNavigate()

  async function submit(otp, value, setSubmitting) {
    try {
      const response = await dispatch(validatePassword({ otp, value })).unwrap()
      Toast("success", response?.message)
      navigate(loginPathName)
    } catch (error) {
      Toast("error", error?.message)
    } finally {
      setSubmitting(false)
    }
  }

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema: NewPasswordSchema,
    onSubmit: async (value, { setSubmitting }) => {
      submit(value.otp, { new_password: value.new_password }, setSubmitting)
    },
  })

  const handleOtpChange = (otp) => {
    setFieldValue("otp", otp)
  }

  return {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    isSubmitting,
    isLoading,
    handleOtpChange,
  }
}

export default useNewPassword
