import { useFormik } from "formik"
import { loginValidations } from "../../../common/validation/loginValidation"
import { Toast } from "../../../components/toastify"
import { useDispatch, useSelector } from "react-redux"
import { customerLoginRequest } from "../../../Redux/action/authAction"
import {
  dashboardPathName,
  verifyPathName,
} from "../../../components/routes/pathName"
import { useNavigate } from "react-router-dom"

const useLogin = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { initialValues } = useSelector((state) => state.auth)

  async function submitForm(value) {
    try {
      sessionStorage.setItem("email", value.email_address)
      const result = await dispatch(customerLoginRequest(value)).unwrap()
      Toast("success", result.message || "Login successful")
      navigate(dashboardPathName)
    } catch (error) {
      if (error?.message === "Customer not verified") {
        Toast(
          "warning",
          "Your email is yet to be verified, Kindly check your email for otp"
        )
        navigate(verifyPathName)
      } else {
        Toast("error", error?.message || "Login failed")
      }
    }
  }

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: loginValidations,
    onSubmit: (value) => {
      submitForm(value)
    },
  })

  return {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
  }
}

export default useLogin
