import React, { useState } from "react"
import Back from "../../components/shared-components/backButton"
import Profile from "./Profile"
import Security from "./Security"

const Settings = () => {
  const [next, setNext] = useState(0)
  const tabHeader = ["Profile", "Security"]
  const tabs = [<Profile />, <Security />]
  function handleNext(index) {
    setNext(index)
  }

  return (
    <>
      <div className=" w-full flex items-center gap-2">
        <Back />
        <p>Settings</p>
      </div>
      <div>
        <h2 className="font-montserrat text-[1.25rem] font-normal mt-[2rem] ">
          Account {tabHeader[next]}
        </h2>
      </div>
      <ul className="flex text-[1rem] font-medium gap-x-[2.5rem] w-fit py-[0.8rem] px-[1rem] bg-[#F9F9F9] mt-[1.75rem] rounded-[0.5rem] ">
        {tabHeader.map((data, i) => (
          <li
            className={` ${
              next === i &&
              "text-primary-300 border-b-4 pb-1 px-10 border-primary-300 "
            } cursor-pointer`}
            onClick={() => handleNext(i)}
            key={i}
          >
            {data}
          </li>
        ))}
      </ul>
      <div className="mt-[1.5rem]">{tabs[next]}</div>
    </>
  )
}

export default Settings
