import { useFormik } from "formik"
import { Toast } from "../../components/toastify"
import { newPasswordResetPathName } from "../../components/routes/pathName"
import { useNavigate } from "react-router-dom"
import { resetPassword } from "../../common/validation/resetPasswordValidation"
import { useDispatch, useSelector } from "react-redux"
import { passwordResetRequest } from "../../Redux/action/passwordResetAction"

const usePasswordReset = () => {
  const dispatch = useDispatch()
  const { email_address, isLoading } = useSelector(
    (state) => state.passwordReset
  )
  const navigate = useNavigate()

  async function submitForm(value, setSubmitting) {
    try {
      const response = await dispatch(passwordResetRequest(value)).unwrap()

      Toast("success", response.message)
      navigate(newPasswordResetPathName)
    } catch (error) {
      Toast("error", error?.message)
    } finally {
      setSubmitting(false)
    }
  }

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: { email_address: email_address },
    validationSchema: resetPassword,
    onSubmit: async (value, { setSubmitting }) => {
      submitForm(value, setSubmitting)
    },
  })

  return {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    isLoading,
  }
}

export default usePasswordReset
