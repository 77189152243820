import { Link } from "react-router-dom"
import Logo from "../../components/shared-components/LogoComponent"
import { loginPathName, landingPathName } from "../../components/routes/pathName"

const PageLayout = ({ images, children }) => {
  return (
    <div className="w-full flex flex-col justify-between  items-center min-h-screen font-montserrat">
      <div className="w-full md:w-[80%] space-y-2">
        <Link to={landingPathName} className="px-[2rem] md:px-0">
          <Logo />
        </Link>

        <div className="w-full flex md:bg-[#f2f1f1] md:rounded-[1.2rem] overflow-hidden">
          <div className="hidden xl:block w-[48%]">
            <img
              src={images}
              alt="images"
              className="object-right object-cover max-w-full h-screen"
            />
          </div>
          <div className="flex items-center w-full  xl:w-[52%] px-[2rem] md:px-[4rem] py-[2rem] ">
            {children}
          </div>
        </div>
      </div>

      <p className="text-center text-[0.75rem] md:mt-[2rem] sm:text-[1rem] text-[#3A3A3C]">
      COPYRIGHT &copy; {new Date().getFullYear()} ALL RIGHTS RESERVED BY {" "}
        <span className="font-bold">LOGISTIFLY</span>
      </p>
    </div>
  )
}

export default PageLayout
