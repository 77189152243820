import React from "react"

const ShipmentIcon = ({ fill }) => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.9588 5.93632C22.9588 5.91095 22.9588 5.91095 22.9588 5.88558C22.9335 5.83485 22.9335 5.78411 22.9081 5.73337V5.708C22.8827 5.65726 22.832 5.60653 22.8066 5.58116L22.7813 5.55579C22.7559 5.53042 22.7052 5.50505 22.6798 5.47968L22.6544 5.45431H22.6291L22.6037 5.42894L11.8219 0.0761067C11.619 -0.0253689 11.3653 -0.0253689 11.1369 0.0761067L7.50919 1.8773L18.3417 7.43309L18.3671 7.45845C18.3924 7.45845 18.3924 7.48382 18.4178 7.48382C18.4432 7.50919 18.4432 7.53456 18.4686 7.55993C18.4686 7.5853 18.4686 7.5853 18.4686 7.61067V13.5216C18.4686 13.6231 18.4178 13.6992 18.3417 13.7499L16.16 14.8915C16.0331 14.9676 15.8809 14.9169 15.8048 14.7901C15.7795 14.7647 15.7795 14.714 15.7795 14.6632V8.90448L4.82009 3.24722L4.79472 3.22185L0.405902 5.40357L0.380533 5.42894H0.355164L0.329796 5.45431C0.304427 5.47968 0.253689 5.50505 0.22832 5.53042L0.202951 5.55579C0.152213 5.60653 0.126844 5.65726 0.0761067 5.708V5.73337C0.0507378 5.78411 0.0253689 5.83485 0.0253689 5.88558C0.0253689 5.91095 0.0253689 5.91095 0.0253689 5.93632C0.0253689 5.98706 0 6.01243 0 6.06317V17.9104C0 18.1895 0.152213 18.4686 0.431271 18.5954L11.1116 23.9229C11.2638 23.999 11.4414 24.0243 11.619 23.9736L11.6697 23.9482C11.7204 23.9482 11.7458 23.9229 11.7965 23.8975L22.5529 18.57C22.8066 18.4432 22.9842 18.1895 22.9842 17.8851V6.06317C22.9588 6.01243 22.9588 5.98706 22.9588 5.93632Z"
        fill={fill}
      />
    </svg>
  )
}

export default ShipmentIcon
