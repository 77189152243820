import { createSlice } from "@reduxjs/toolkit"
import { lodgeShipmentRequest } from "../action/lodgeShipmentAction"

const lodgeShipmentSlice = createSlice({
  name: "lodgeShipment",
  initialState: {
    initialValues: {
      // shipment_type: "",
      // shipment_item_worth: null,
      // shipping_quantity: null,
      // lenght: null,
      // height: null,
      // width: null,
      sender_name: "",
      sender_email: "",
      // sender_address: "",
      sender_country: "",
      weight: null,
      receiver_country: "",
      receiver_name: "",
      receiver_email: "",
      // isFragile: null,
      // isLiquid: null,
    //  drop_off_date: "",
     
      // receiver_address: "",
      // receiver_phone: "",
      // waybill_no: "",

      discount_code: "",
    },
    shipment: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(lodgeShipmentRequest.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(lodgeShipmentRequest.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.shipment = payload
    })
    builder.addCase(lodgeShipmentRequest.rejected, (state, { payload }) => {
      state.isLoading = false
      state.error = payload.error
    })
  },
})

export default lodgeShipmentSlice.reducer
