import React from 'react'

const LandingInputField = ({...props}) => {



    return (
        <input {...props}  required className="w-full block border-none outline-none" />
      
  )
}

export default LandingInputField
