import React from 'react'

const Logo = () => {
  return (
    <div className=''>
        <p className='text-[1.5rem] xl:text-[2.2rem] text-primary-300 italic font-playfair font-black'>Logistifly</p>
    </div>
  )
}

export default Logo