import PageLayout from "../../common/pageLayout"
import signUp from "../../assets/images/sign-up.png"
import OtpInput from "react-otp-input"
import Button from "../../components/shared-components/Button"
import VerificationModal from "./verificationModal"
import useVerify from "./useVerify"
import useResendOtp from "./useResendOtp"

const Verify = () => {
  const {
    values,
    handleSubmit,
    modal,
    fieldValues,
    isSubmitting,
    errors,
    touched,
    countdown,
  } = useVerify()

  const { isResendDisabled, resetCountDown, handleResendCode } = useResendOtp()

  return (
    <>
      <PageLayout images={signUp}>
        <form className="w-full space-y-[1.5rem]">
          <h1 className="text-[2.5rem] font-bold">Verification</h1>
          <p className="text-[0.9rem] w-[18rem]">
            We have sent a six (6) digit code to your email
          </p>
          <div className="relative">
            <OtpInput
              value={values.otp}
              onChange={fieldValues}
              numInputs={6}
              renderSeparator={<span> </span>}
              renderInput={(props) => (
                <input
                  {...props}
                  style={{
                    width: "3rem",
                    height: "3rem",
                    margin: "0 auto",
                    borderRadius: "0.5rem",
                    textAlign: "center",
                    outline: "none",
                    border: "1px solid grey",
                  }}
                />
              )}
            />
            {touched.otp && errors.otp && (
              <small className="text-red-500 absolute left-6 -bottom-5">
                {errors.otp}
              </small>
            )}
          </div>
          <div className="space-y-3">
            <Button
              type={"button"}
              text={"Verify"}
              onClick={handleSubmit}
              className={`text-white w-full ${
                isSubmitting && "!bg-opacity-30"
              }`}
              disabled={isSubmitting}
            />
            <Button
              type="button"
              onClick={handleResendCode}
              disabled={isResendDisabled}
              className={`!text !text-[0.8rem] !px-2 !py-2 !text-white ${
                isResendDisabled
                  ? "!bg-gray-400 cursor-not-allowed"
                  : "!bg-[#0c93cda5]"
              }`}
              text={
                isResendDisabled
                  ? `Resend Code in ${resetCountDown}s`
                  : "Resend Code"
              }
            />
          </div>
        </form>
      </PageLayout>
      {modal && <VerificationModal countdown={countdown} />}
    </>
  )
}

export default Verify
