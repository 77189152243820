import { configureStore } from "@reduxjs/toolkit"
import authSlice from "./slices/authSlice"
import getCustomerSlice from "./slices/customerSlice"
import getQuoteSlice from "./slices/getQuoteSlice"
import profileSlice from "./slices/profileSlice"
import changePasswordSlice from "./slices/changePasswordSlice"
import signUpSlice from "./slices/signUpSlice"
import otpReducer from "./slices/otpSlice"
import passwordResetSlice from "./slices/passwordResetSlice"
import newPasswordSlice from "./slices/newPasswordSlice"
import lodgeShipmentSlice from "./slices/lodgeShipmentSlice"
import getShipmentSlice from "./slices/getShipmentSlice"
import getSingleShipmentSlice from "./slices/getSingleShipmentSlice"
import getAllCountriesSlice from "./slices/getAllCountriesSlice"

const store = configureStore({
  reducer: {
    auth: authSlice,
    getCustomer: getCustomerSlice,
    getQuote: getQuoteSlice,
    profile: profileSlice,
    changePassword: changePasswordSlice,
    signUp: signUpSlice,
    otp: otpReducer,
    passwordReset: passwordResetSlice,
    newPassword: newPasswordSlice,
    lodgeShipment: lodgeShipmentSlice,
    getShipment: getShipmentSlice,
    getSingleShipment: getSingleShipmentSlice,
    getAllCountries: getAllCountriesSlice,
    // Add more
  },
  devTools: process.env.NODE_ENV !== "production",
})

export default store
