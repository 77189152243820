import Button from "../../../components/shared-components/Button"
import RenderInput from "../renderInput"
import { Link } from "react-router-dom"
import {
  passwordResetPathName,
  signUpPathName,
} from "../../../components/routes/pathName"
import useLogin from "./useLogin"

const LoginForm = () => {
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    // isSubmitting,
  } = useLogin()

  return (
    <form onSubmit={handleSubmit} className="w-full space-y-[1.2rem]">
      <p className="text-[1.25rem] font-light ">Welcome back!</p>
      <h1 className="text-[2.5rem] font-bold ">Login</h1>
      {Object.entries(values).map(([field, value], i) => (
        <div key={i}>
          {RenderInput(field, value, handleChange, handleBlur, errors, touched)}
        </div>
      ))}
      <div className="flex justify-end mt-0 ">
        <Link
          to={passwordResetPathName}
          className="text-[0.9rem] text-primary-300 font-bold cursor-pointer"
        >
          Forgot password?
        </Link>
      </div>
      <div>
        <Button
          type={"submit"}
          text={"Login"}
          className={"text-white w-full "}
        />
        <div className="mt-[1.4rem] ">
          <p className="text-[0.9rem] ">
            Don’t have an account?{" "}
            <Link
              to={signUpPathName}
              className="text-primary-300 font-bold cursor-pointer"
            >
              Sign up
            </Link>
          </p>
        </div>
      </div>
    </form>
  )
}

export default LoginForm
