import { createSlice } from "@reduxjs/toolkit"
import { changePassword } from "../action/changePasswordAction"

const changePasswordSlice = createSlice({
  name: "settings",
  initialState: {
    initialValues: {
      current_password: "",
      new_password: "",
      confirm_new_password: "",
    },
    user: null,
    error: null,
    isLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(changePassword.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.user = payload
    })
    builder.addCase(changePassword.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(changePassword.rejected, (state, { payload }) => {
      state.isLoading = false
      state.error = payload.error
    })
  },
})

export default changePasswordSlice.reducer
