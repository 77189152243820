import { BrowserRouter, Route, Routes } from "react-router-dom"
import "./App.css"
import { pageRoute, protectedRoutes } from "./components/routes"
import Container from "./components/Container"
import ProtectedRoute from "./common/services/protectedRoute"
import ScrollToTop from "./components/shared-components/ScrollToTop"

const RenderRoute = ({ data, isProtected }) => {
  const Element = data.element
  const content = data.layout ? (
    <Container>
      <Element />
    </Container>
  ) : (
    <Element />
  )

  return isProtected ? <ProtectedRoute>{content}</ProtectedRoute> : content
}

function App() {
  const allRoutes = [
    ...pageRoute.map((route) => ({ ...route, isProtected: false })),
    ...protectedRoutes.map((route) => ({ ...route, isProtected: true })),
  ]

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {allRoutes.map((data, i) => (
          <Route
            key={i}
            path={data.path}
            element={<RenderRoute data={data} isProtected={data.isProtected} />}
          />
        ))}
      </Routes>
    </BrowserRouter>
  )
}

export default App
