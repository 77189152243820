import React from 'react'
import PageLayout from '../../common/pageLayout'
import signUp from "../../assets/images/sign-up.png"
import SignUpForm from './signUpForm'

const SignUp = () => {
  return (
    <PageLayout images={signUp} ><SignUpForm/></PageLayout>
  )
}

export default SignUp