import { createSlice } from "@reduxjs/toolkit"
import { validatePassword } from "../action/newPasswordAction"

const newPasswordSlice = createSlice({
  name: "newPassword",
  initialState: {
    initialValues: {
      otp: "",
      new_password: "",
      confirm_password: "",
    },
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(validatePassword.fulfilled, (state, { payload }) => {
      state.isLoading = false
    })
    builder.addCase(validatePassword.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(validatePassword.rejected, (state, { payload }) => {
      state.isLoading = false
      state.error = payload.error
    })
  },
})

export default newPasswordSlice.reducer
