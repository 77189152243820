import React from "react"

const InputField = ({ className,touched,errors,field, ...props }) => {
  return (
    <div className="relative">
      <input
        {...props}
        className={`border-none h-[2.7rem] ${className}  rounded-[0.5rem] pt-[2rem] pb-[1.5rem] px-[1.5rem] w-full outline-none`}
      />
      {touched && errors && (
        <small className="text-red-500 absolute left-0 -bottom-5">
          {errors}
        </small>
      )}
    </div>
  )
}

export default InputField
