import { createSlice } from "@reduxjs/toolkit"
import { customerDataRequest } from "../action/customerAction"

const getCustomerSlice = createSlice({
  name: "customer",
  initialState: {
    customer: {},
    isLoading: false,
    error: null,
  },
  reducers: {
    setInitialState: (state, { payload }) => {
      state.customer = payload
      state.isLoading = false
      state.error = null
    },
  },
  extraReducers: (builders) => {
    builders.addCase(customerDataRequest.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.customer = payload

    })
    builders.addCase(customerDataRequest.pending, (state) => {
      state.isLoading = true
    })
    builders.addCase(customerDataRequest.rejected, (state, { payload }) => {
      state.isLoading = false
      state.error = payload.error
    })
  },
})

export const { setInitialState } = getCustomerSlice.actions
export default getCustomerSlice.reducer
