import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { updateProfile } from "../../Redux/action/profileAction"
import { profileSchema } from "../../common/validation/updateProfileValidation"
import { Toast } from "../../components/toastify"

const useProfile = () => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector((state) => state.profile)
  const { customer } = useSelector((state) => state.getCustomer)
  async function handleUpdate(values, setSubmitting) {
    try {
      const response = await dispatch(updateProfile(values)).unwrap()
      Toast("success", response.message)
    } catch (error) {
      Toast("error", error.message)
    } finally {
      setSubmitting()
    }
  }

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      registration_type: customer.registration_type,
      firstname: customer.firstname,
      lastname: customer.lastname,
      email_address: customer.email_address,
      phone_number: customer.phone_number,
    },
    validationSchema: profileSchema,
    onSubmit: async (values, { setSubmitting }) => {
      handleUpdate(values, setSubmitting)
    },
    enableReinitialize: true,
  })

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
    isLoading,
  }
}

export default useProfile
