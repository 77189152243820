import * as Yup from "yup"

export const profileSchema = Yup.object({
  //   registration_type: Yup.string().required("Registration type is required"),
  firstname: Yup.string().required("First name is required"),
  lastname: Yup.string().required("Last name is required"),
  //   email_address: Yup.string()
  //     .email("Invalid email address")
  //     .required("Email address is required"),
  //   phone_number: Yup.string()
  //     .matches(
  //       /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
  //       "Invalid phone number"
  //     )
  //     .required("Phone number is required"),
})
