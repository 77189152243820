const Heading = ({ headingH1, className }) => {
  return (
    <>
      <h1
        className={`hidden sm:block text-[20px] sm:text-[48.8px] leading-[23.05px] font-semibold font-montserrat text-[#595757] ${className}`}
      >
        {headingH1}
      </h1>
    </>
  );
};

export default Heading;
