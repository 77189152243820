import axios from "axios"
import { axiosApi } from "../../../util/axiosInstance"

export const postRequest = (
  baseUrl,
  data,
  contentType = "application/json"
) => {
  return axiosApi({
    method: "POST",
    url: baseUrl,
    headers: {
      "Content-Type": contentType,
      // Authorization: `Bearer ${token}`,
    },
    data: data,
  })
}

export const getRequest = (baseUrl) => {
  return axiosApi({
    method: "GET",
    url: baseUrl,
  })
}

export const putRequest = (baseUrl, data) => {
  return axiosApi({
    method: "PUT",
    url: baseUrl,
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
    data: data,
  })
}

export const patchRequest = (baseUrl, data) => {
  return axiosApi({
    method: "PATCH",
    url: baseUrl,
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
    data: data,
  })
}

export const deleteRequest = (baseUrl) => {
  return axios({
    method: "DELETE",
    url: baseUrl,
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
  })
}

export const postFileData = (
  baseUrl,
  data,
  token = null,
  contentType = "multipart/form-data"
) => {
  const formData = new FormData()
  formData.append("file", data)
  return postRequest(baseUrl, formData, (token = null), contentType)
}
