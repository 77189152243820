import React, { useEffect, useRef, useState } from "react"
import Back from "../../components/shared-components/backButton"
import DataCard from "./DataCard"
import TrackingMap from "./TrackingMap"
import { getSingleShipmentRequest } from "../../Redux/action/getSingleShipmentAction"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { fullDate } from "../../util"
import useClickOut from "../../util/useClickOut"

const SingleShipment = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const {shipment} = useSelector((state) => state.getShipment) //get all shipments
  const singleShipment = shipment.filter((data) => data.lodge_shipment_id === id) //get single shipment

  useEffect(() => {

    dispatch(getSingleShipmentRequest(id)).unwrap()
  }, [dispatch, id])

  const [showMap, setShowMap] = useState(false)
  const mapRef = useRef()

  useClickOut(mapRef, () => setShowMap(false))

  console.log("singleShipment", singleShipment)

  const detailOne = [
    // { label: "Recipient", value: singleShipment[0]?.receiver_name || "" },
    { label: "Shipment Status", value: singleShipment[0]?.status || "" }, 
    { label: "Waybill No", value: singleShipment[0]?.waybill_no || "" }, 
    { label: "Shipment type",  value: `${singleShipment[0]?.shipment_type}` || "" },
    { label: "Items worth",  value: `₦${singleShipment[0]?.shipment_item_worth?.toLocaleString()}` || 0},
    { label: "Fragile Item", value: singleShipment[0]?.isFragile === 1 ? 'YES' : 'NO' || "" },
    { label: "Liquid Item", value: singleShipment[0]?.isLiquid === 1 ? 'YES' : 'NO' || "" },
    { label: "Approximate Length", value: singleShipment[0]?.lenght || "" },
    { label: "Approximate Height", value: singleShipment[0]?.height || "" },
    { label: "Approximate Width", value: singleShipment[0]?.width || "" },
    { label: "Quantity", value: singleShipment[0]?.shipping_quantity || "n/a" },
    //{ label: "Quantity", value: fullDate(singleShipment[0]?.drop_off_date) || "" },
    // { label: "Type", value: "Debit" },
    // { label: "Status", value: singleShipment?.status || "" },
  ]

  const detailTwo = [
    { label: "Sender Fullname", value: singleShipment[0]?.sender_name || "" },
    { label: "Sender Email", value: singleShipment[0]?.sender_email || "" },
    { label: "Sender Address", value: `${singleShipment[0]?.sender_address }` || ""},
    { label: "Created Date", value: singleShipment[0]?.created_at.split('T')[0] || "" },
  ]

  const detailThree = [

    { label: "Receiver Fullname", value: singleShipment[0]?.receiver_name || "" },
    { label: "Receiver Email", value: singleShipment[0]?.receiver_email || "" },
    { label: "Receiver Address", value: `${singleShipment[0]?.receiver_address }` || ""},
    { label: "Receiver Phone", value: singleShipment[0]?.receiver_phone || "" },
  ]

  return (
    <div className="space-y-[2rem]">
      <div className="flex items-center gap-2 max-w-fit">
        <Back />
        <p className="font-medium">
          Shipments ID:{" "}
          <span className="text-[1rem] text-[#4A515C] font-normal">
            4654746823
          </span>
        </p>
      </div>

    
      <div>
        <DataCard title={"Sender Details"} details={detailTwo} />
      </div>
      <div>
        <DataCard title={"Receivers Details"} details={detailThree} />
      </div>
      <div>
        <DataCard title={"Shipment details"} details={detailOne} />
      </div>
      <div className="space-y-[0.5rem]">
        <div className="flex justify-between items-center">
          <p className="text-[1rem] md:text-[1.25rem] text-[#979797] font-gothic">
            Shipment progress
          </p>
          <div
            onClick={() => setShowMap(true)}
            className="border border-[#0E94CD] text-[0.5rem] text-[#979797] cursor-pointer sm:hidden rounded-[0.5rem] py-[0.3rem] px-[0.5rem]"
          >
            View map
          </div>
        </div>

        <div className="w-full sm:flex justify-between">
          <div className="w-full sm:w-[45%]">
            <div className="text-[0.75rem] sm:text-[1rem] text-[#979797] xl:text-black sm:font-bold rounded-[0.5rem] py-[0.8rem] px-[2rem] bg-[#EFFAFF]">
              Timeline
            </div>
          </div>
          <div
            className={`fixed top-0 left-0 z-40 sm:z-0 overflow-y-auto ${
              showMap ? "block" : "hidden"
            } sm:block sm:relative w-full min-h-screen sm:h-fit sm:w-1/2 sm:bg-[#EFFAFF] rounded-[0.5rem] p-4 bg-black bg-opacity-20`}
          >
            <div ref={mapRef}>
              <TrackingMap />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleShipment
